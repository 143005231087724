import React from 'react';
//import classNames from "classnames";
//import { connect } from 'react-redux';
//import Victor from "victor";
//import $ from 'jquery';

//css and images
import './loading.scss';

class Loading extends React.PureComponent {

    render() {
      let size = this.props.size ? this.props.size : 64;
      let color = this.props.color;

      let resizeRatio = size / 64;

      let ringStyle = {
        width: 64 * resizeRatio,
        height: 64 * resizeRatio
      };
      let divStyle = {
        borderColor: color + " transparent transparent transparent",
        width: 51 * resizeRatio,
        height: 51 * resizeRatio,
        borderWidth: 6 * resizeRatio,
        margin: 6 * resizeRatio,
      };
      return (
        <div className="lds-ring" style={ringStyle}><div style={divStyle}></div><div style={divStyle}></div><div style={divStyle}></div><div style={divStyle}></div></div>
        );
    }
}

export default Loading;
