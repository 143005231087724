import React from 'react';
//import classNames from "classnames";
//import { connect } from 'react-redux';

//import Loading from "../Loading";

//css and images
import './formPage.scss';

class FormPage extends React.PureComponent {

    render() {
        return (
            <form className="form_style" onSubmit={this.props.handleFormSubmit}>
                <div>
                    <div className="publish_share_item">
                        <img src={this.props.imgicon} className="social_item_icon"/>
                        <div className="publish_item">{this.props.page.name}</div>
                    </div>
                </div>
                <div className="form_container">
                    <input type="hidden" ref="page_id" name="page_id" id="page_id" value={this.props.page.id}/>
                    <input type="hidden" ref="access_token" name="access_token" id="access_token" value={this.props.page.access_token}/>
                    <input type="hidden" ref="design_link" name="design_link" id="design_link" value={this.props.page.access_token}/>
                    <textarea ref="title" className="form_textbox" placeholder="file title" rows="1" />
                    <textarea ref="message" className="form_textbox" placeholder="Write something..." rows="5" />
                    <input type="hidden" ref="page_name" name="page_name" id="page_name" value={this.props.page.name}/>
                    <div className="from_btn_container"><div className="form_btn_flex_style"><div>
                        <button className="form_btn" type="submit">
                            <div>
                                <span className="header_fonts_family create_design_text">Publish</span>
                            </div>
                        </button>
                    </div></div></div>
                </div>
            </form>
        );
    }
}

export default FormPage;
