import React from 'react';
import classNames from "classnames";
import { connect } from 'react-redux';

import Page from '../Page';

import plus_icon from './plus.svg';
import minus_icon from './minus.svg';
import preview_icon from './preview.svg';

import {
    goPreviewPage,
    togglePreviewMin,
} from '../../actions';

//css and images
import './PreviewPanel.scss';


class PreviewPanel extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.handleToggleHide = this.handleToggleHide.bind(this)
    }

    handleToggleHide() {
        this.props.togglePreviewMin()
    }

    handlePageClick(index) {
        this.props.goPreviewPage(index)
        return false;
    }

    render() {
        let currentDesignBackground = this.props.currentDesignBackground && this.props.currentDesignBackground.previews && this.props.currentDesignBackground.previews[this.props.previewPage] ? this.props.currentDesignBackground.previews[this.props.previewPage] : null;
        let printingWidth = currentDesignBackground ? currentDesignBackground.design_canvas_location.width : 0;
        let printingHeight = currentDesignBackground ? currentDesignBackground.design_canvas_location.height : 0;
        let designPageIdx = currentDesignBackground && currentDesignBackground.design_page && this.props.pages[currentDesignBackground.design_page] ? currentDesignBackground.design_page : 0
        let printingZoom = 1;
        let productBackgroundSize = 0;
        if (printingWidth != 0 && printingHeight != 0 && this.props.pages && this.props.pages.length) {
            if (this.props.pages[designPageIdx].width / printingWidth > this.props.pages[designPageIdx].height / printingHeight ) {
                printingZoom = this.props.pages[designPageIdx].width / printingWidth;
                productBackgroundSize = currentDesignBackground.design_background_width;
            } else {
                printingZoom = this.props.pages[designPageIdx].height / printingHeight;
                productBackgroundSize = currentDesignBackground.design_background_height;
            }
        }
        let zoomScale = 270 / productBackgroundSize / printingZoom;
        let designBackgroundStyle = {position: 'absolute'};

        let backgroundOffsetLeft = 0;
        let backgroundOffsetTop = 0;
        if (currentDesignBackground.design_background_width > currentDesignBackground.design_background_height) {
            backgroundOffsetLeft = -(currentDesignBackground.design_background_width - currentDesignBackground.design_background_height) / 2;
        } else {
            backgroundOffsetTop = -(currentDesignBackground.design_background_height - currentDesignBackground.design_background_width) / 2;
        }

        designBackgroundStyle.top = (backgroundOffsetTop + currentDesignBackground.design_canvas_location.top) * printingZoom * zoomScale;
        designBackgroundStyle.left = (backgroundOffsetLeft + currentDesignBackground.design_canvas_location.left) * printingZoom * zoomScale;

        return (
            <div className={classNames("project_preview_container")}>
                <div className="project_preview_header">
                <a href="#" onClick={this.handleToggleHide} className="project_preview_icon_left">{this.props.showFullView ? (<img src={minus_icon} />) : (<img src={plus_icon} />)}</a>
                Preview
                <a href="#" className="project_preview_icon_right"><img src={preview_icon} /></a>
                </div>
                {this.props.showFullView ? (<div className="project_preview_content">
                    <div style={designBackgroundStyle}>
                    <Page
                      key={"preview_" + this.props.pages.hash}
                      data={this.props.pages[designPageIdx]}
                      containerId="preview-container"
                      previewOnly={true}
                      currentDesignBackground={this.props.currentDesignBackground && this.props.currentDesignBackground.previews && this.props.currentDesignBackground.previews[this.props.previewPage] ? this.props.currentDesignBackground.previews[this.props.previewPage] : null}
                      idx={designPageIdx}
                      no_side={true}
                      zoomScale={zoomScale}
                    />
                    </div>
                </div>) : null}
                {this.props.showFullView ? (<div className="project_preview_pagination">{
                    this.props.currentDesignBackground.previews.map((preview, index) => (<a href="#" key={'pagination_' + index} onClick={this.handlePageClick.bind(this, index)} className={classNames('project_preview_bullet', {current_page: index == this.props.previewPage})}>&nbsp;</a>))
                }</div>) : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
  let currentDesignBackground = null;
  if (state.currentProjectOptions && state.projectOptions && state.projectOptions.images) {
      let imageData = state.projectOptions.images.find(image => {
          let match = true;
          for (let option in state.currentProjectOptions) {
              let variation = state.currentProjectOptions[option];
              if (image[option] != variation) {
                  match = false;
                  break;
              }
          }
          return match;
      })
      if (imageData) {
          currentDesignBackground = imageData;
      }
  }

  let previewPage = 0;
  if (currentDesignBackground.previews && state.previewPage && currentDesignBackground.previews[state.previewPage]) {
    previewPage = state.previewPage
  }
  return {
    pages: state.pages,
    currentDesignBackground: currentDesignBackground,
    previewPage: previewPage,
    showFullView: !state.previewPanelMin
  }
};

const mapDispatchToProps = dispatch => ({
    goPreviewPage: (page) => dispatch(goPreviewPage(page)),
    togglePreviewMin: () => dispatch(togglePreviewMin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPanel);
