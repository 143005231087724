import React from 'react';
import classNames from "classnames";
import { connect } from 'react-redux';

import close_icon from './close-button.svg';


import {
    toggleItemVisibility,
    setCurrentProjectOption
} from '../../actions';

//css and images
import './productOptionPanel.scss';


class ProductOptionPanel extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.handlePreventDefault = this.handlePreventDefault.bind(this);
        this.handleCloseBtnClicked = this.handleCloseBtnClicked.bind(this);
    }

    handlePreventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    handleVariationClicked(option, variation, e) {
      this.props.setCurrentProjectOption(option, variation);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    handleCloseBtnClicked() {
      this.props.toggleItemVisibility('productOptionPanel')
    }

    render() {
      return (<div className="product-option-panel" onMouseUp={this.handlePreventDefault}>
          <a href="#" onMouseUp={this.handleCloseBtnClicked} ><img className="close-btn" src={close_icon} /></a>
          {this.props.projectOptions.options.filter((option) => parseInt(option.is_show_engine)).map((option) => {
            return (<div key={"option_" + option.name} className="option-container">
                <h5 className="option-title">{option.displayName}</h5>
                {option.variations.map((variation) => {
                  if (option.name == 'color') {
                    return (<div key={"variation_" + variation.name} onMouseUp={this.handleVariationClicked.bind(this, option.name, variation.name)} className={classNames("variation", "color", {active: this.props.currentProjectOptions[option.name] == variation.name})} style={{backgroundColor: variation.select_content ? variation.select_content : variation.displayName}}></div>)
                  } else {
                    var img = null;
                    var displayName = variation.displayName;
                    if(variation.options_variations_img != null && variation.options_variations_img != ""){
                      img = (<img className="variation_img py-2" src={variation.options_variations_img} />);
                      displayName = null;
                    }
                    return (<div key={"variation_" + variation.name} onMouseUp={this.handleVariationClicked.bind(this, option.name, variation.name)} className={classNames("variation", {active: this.props.currentProjectOptions[option.name] == variation.name})}><div className="text-content"> {img}{displayName}</div></div>)
                  }
                })}
              </div>)
          })}
        </div>)
    }
}

const mapStateToProps = state => {
  return {
    projectOptions: state.projectOptions,
    currentProjectOptions: state.currentProjectOptions,
  }
};

const mapDispatchToProps = dispatch => ({
  toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
  setCurrentProjectOption: (option, variation) => dispatch(setCurrentProjectOption(option, variation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductOptionPanel);
