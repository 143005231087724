export default
[
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large.jpg",
        title: 'Social Media'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large1.jpg",
        title: 'Presentation'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large3.jpg",
        title: 'Facebook Cover'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large4.jpg",
        title: 'Flyer'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large5.jpg",
        title: 'Facebook Post'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large6.jpg",
        title: 'Instragram Post'
    },
    {
        photoUrl: "http://test.joindesign.com/images/featured_design/thumbnail_large7.jpg",
        title: 'Poster'
    }
]
