export const loadShippingCountryList = lang => ({
    type: 'LOAD_SHIPPING_COUNTRY_LIST',
    lang
});

export const loadPaymentCurrencyList = () => ({
    type: 'LOAD_PAYMENT_CURRENCY_LIST',
});

export const detectUserCountryByIP = () => ({
    type: 'DETECT_USER_COUNTRY_BY_IP',
});

export const fetchQuote = () => ({
    type: 'FETCH_QUOTE',
});

export const setVariation = (key, value) => ({
    type: 'SET_VARIATION',
    key,
    value
});

export const setPriceOption = value => ({
    type: 'SET_PRICE_OPTION',
    value
});

export const setShippingMethod = shippingMethod => ({
    type: 'SET_SHIPPING_METHOD',
    shippingMethod
});

export const nextCheckoutStep = () => ({
    type: 'NEXT_CHECKOUT_STEP',
});

export const prevCheckoutStep = () => ({
    type: 'PREV_CHECKOUT_STEP',
});

export const resetCheckoutStep = () => ({
    type: 'RESET_CHECKOUT_STEP',
});

export const setAddress = (field, value) => ({
    type: 'SET_ADDRESS',
    field,
    value
});
export const setPrintFile = printFile => ({
    type: 'SET_PRINTFILE',
    printFile
});

export const setCurrency = curr => ({
    type: 'SET_CURRENCY',
    curr
});
export const createOrder = (callback, paymentNonce, paymentAmount,payment_term,select_from_team_id) => ({
    type: 'CREATE_ORDER',
    callback,
    paymentNonce,
    paymentAmount,
    payment_term,
    select_from_team_id
});


export const setCheckoutLoading = isLoading => ({
    type: 'SET_CHECKOUT_LOADING',
    isLoading
});

export const setCheckoutError = error => ({
    type: 'SET_CHECKOUT_ERROR',
    error
});

export const validateAddress = (lang, callback) => ({
    type: 'VALIDATE_ADDRESS',
    lang,
    callback
});

export const setTermConfirm = (term, confirm) => ({
    type: 'SET_TERM_CONFIRM',
    term,
    confirm
});


export const setModalRandom = random => ({
  type: 'SET_MODAL_RANDOM',
  random
});
