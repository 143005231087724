import React, { Suspense, lazy } from 'react';
import classNames from "classnames";

import TextImg from './text.png';
import TextImg_selected from './text_selected.png';

import ImageImg from './element.png';
import ImageImg_selected from './element_selected.png';

import CornersImg from './background.png';
import CornersImg_selected from './background_selected.png';

import LayoutImg from './layout.png';
import LayoutImg_selected from './layout_selected.png';

import MineImg from './upload.png';
import MineImg_selected from './upload_selected.png';

import CloseArrow from './menu_close_arrow.png';
import OpenArrow from './menu_open_arrow.png';

// import MenuElement from './MenuElement';
import SideMenuControl from './SideMenuControl';

import TextElement from './TextElement';
// import ImageElement from './ImageElement';
// import BackgroundElement from './BackgroundElement';
import LayoutElement from './LayoutElement';
// import MineElement from './MineElement';

import star_unselected from './MenuElement/icon-heart-unselected.png';
import star_selected from './MenuElement/icon-heart-selected.png';

// import Cookies from 'universal-cookie';

import { connect } from 'react-redux';
import { /*uploadMine, */showImageFilter, setDefaultMode, clearAllPopup, closeSideBar, showSideBar, setMyFavouriteFilter, closeElementMenu,changeElementMenu,setPaymentTerm,setSelectFromTeamID,setProjectTarget, } from '../../actions';

// const TextElement = lazy(() => import('./TextElement'));
const ImageElement = lazy(() => import('./ImageElement'));
const BackgroundElement = lazy(() => import('./BackgroundElement'));
//const LayoutElement = lazy(() => import('./LayoutElement'));
const MineElement = lazy(() => import('./MineElement'));

class SideControlPanel extends React.PureComponent {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        /*var side_menu_control= [ {id : "MenuLayout", ref : "MenuLayout", src : LayoutImg, src_selected : LayoutImg_selected, onClick : "layout", selected:false, text:'Templates'},
            {id : "MenuText", ref : "MenuText", src : TextImg, src_selected : TextImg_selected, onClick : "text", selected:false, text:"Text"},
            {id : "MenuImage", ref : "MenuImage", src : ImageImg, src_selected : ImageImg_selected, onClick : "image", selected:false, text:"Elements"},
            {id : "MenuBackground", ref : "MenuBackground", src : CornersImg, src_selected : CornersImg_selected, onClick : "background", selected:false, text:"Bkground"},
            {id : "MenuMine", ref : "MenuMine", src : MineImg, src_selected : MineImg_selected, onClick : "mine", selected:false, text:"Uploads"},
          ];*/
        // side_menu_control=[];
        this.state = ({
          csrf : "",
          current_element : null,
          current_type : "",
          animation_lock : false,
          request_lock : false,
          offset : 0,
          menu_elements : [],
          loading : ["lds_ring"],
          uploading: ["lds_ellipsis"],
          upload_container: ["upload_container","hide"],
          closeMenu: ["closeMenu"],
          stop_append : false,
          limit : 10,
          elementContainer : ["elementContainer"],
          element_select_menu: ["element_select_menu"],
          side_menu_control : [] ,
          menu_closed : false,
          minefiles : null,
          sideMenuRenderElements: null,
          is_SideBar: props.is_SideBar,
          MenuSelectedSelectorTop : 0,
          closeElementMenuClass: props.closeElementMenuClass,
          current_lang: props.current_lang,
          payment_term: props.payment_term,
          select_from_team_id:props.select_from_team_id,
          team:props.team,
          myImages: []
        });

        this.closeMenu = this.closeMenu.bind(this);
        this.sideMenuRenderElements = this.sideMenuRenderElements.bind(this);
        this.changeMyFavouriteFilter = this.changeMyFavouriteFilter.bind(this);
    }

    componentDidMount () {
      this._isMounted = true;
      let type='load';
      if (this.props.project_target=="mp_order" ||this.props.project_target=="mp_product") {
          type='text';
          this.setSideMenu(this.props);
      }
      this.SelectElement/*.bind*/(/*this, */type);
      // window.addEventListener('load', this.SelectElement.bind(this, type), false );
    }

    componentWillUnmount() {
        this._isMounted = false;
        //window.removeEventListener('load', this.SelectElement.bind(this), false );
    }

    UNSAFE_componentWillReceiveProps(props) {
        /*if (!this._isMounted) {
            return;
        }*/
        if (props.closeElementMenuClass) {
          this.setState({
            elementContainer : ["elementContainer", "collapseMenu"],
            element_select_menu : ["element_select_menu", "collapseMenu"],
            closeMenu : ["closeMenu"],
            menu_closed : true,
            MenuSelectedSelectorTop : -100,
          }, function(){
            this.props.updateDesignPanel("hide");
          });
          this.refs.menu_back_arrow.src = OpenArrow;

        }

        if (props.is_SideBar) {
            this.setState({
                elementContainer : ["elementContainer"],
                element_select_menu : ["element_select_menu"],
                menu_closed : false,
                is_SideBar: props.is_SideBar,
                //closeMenu : ["closeMenu", "close"]
            }, function(){
                this.props.updateDesignPanel("show");
            });
        }
        if (props.filterMode !== "default") {
            this.setState({
                closeMenu : ["closeMenu", "collapseMenu"]
            }, function(){
                this.props.updateDesignPanel("show");
            });
        } else {
            this.setState({
                closeMenu : ["closeMenu"]
            });
        }

        if (props.current_lang ) {
          if (props.payment_term && (this.props.payment_term!=props.payment_term || this.props.project_target!=props.project_target) && props.select_from_team_id!=undefined ) {
            this.setSideMenu(props);
          } else if (props.payment_term && this.state.side_menu_control.length==0) {
            this.setSideMenu(props);
          }
        }
    }

    sideMenuRenderElements(type) {
        switch(type) {
            case 'LayoutElement' :
                return <LayoutElement key="layout_element" />;

            case 'TextElement' :
                return <TextElement key="TextElementSideMenu" />;

            case 'ImageElement' :
                return <Suspense fallback={<div></div>}><ImageElement key="image_element" /></Suspense>;

            case 'BackgroundElement' :
                return <Suspense fallback={<div></div>}><BackgroundElement key="background_element" /></Suspense>;

            case 'MineElement' :
                return <Suspense fallback={<div></div>}><MineElement key={'mine_element'+this.state.myImages.length} images={this.state.myImages} setImages={images => {
                    //console.log('setimages', images);
                    return this.setState({myImages: images});
                }
                } /></Suspense>;

        }
    }

    setSideMenu(props) {
        // console.log('setSideMenu', 'type:text')
        var side_menu_control=[];
        let type = 'load';
        let side_menu_text = null;
        if ((props.payment_term==="monthly") && (props.select_from_team_id>0)){
            /*
            side_menu_control=[
              {id : "MenuText", ref : "MenuText", src : TextImg, src_selected : TextImg_selected, onClick : {name:'text',position:0,sideMenuRenderElements:<TextElement key="text_element" />}, selected:false, text:this.props.current_lang === 'en' ? "Text" : '文字'},
              {id : "MenuMine", ref : "MenuMine", src : MineImg, src_selected : MineImg_selected, onClick : {name:'mine',position:1,sideMenuRenderElements:<MineElement key="mine_element" />}, selected:false, text:this.props.current_lang === 'en' ? "Uploads" : '上載'},
            ];
            type = 'text';
            */
            side_menu_control=[];
            type = '';
        }else if (this.props.project_target==="mp_order" ||this.props.project_target==="mp_product") {
            if (this.state.side_menu_text) {
                side_menu_text = this.state.side_menu_text;
            } else {
                side_menu_text = 'TextElement';
            }
            side_menu_control=[
                {id : "MenuText", ref : "MenuText", src : TextImg, src_selected : TextImg_selected, onClick : {name:'text',position:0,sideMenuRenderElements:'TextElement'}, selected:false, text:this.props.current_lang === 'en' ? "Text" : '文字'},
                {id : "MenuImage", ref : "MenuImage", src : ImageImg, src_selected : ImageImg_selected, onClick : {name:'image',position:1,sideMenuRenderElements:'ImageElement'}, selected:false, text:this.props.current_lang === 'en' ? "Elements" : '素材'},
                {id : "MenuBackground", ref : "MenuBackground", src : CornersImg, src_selected : CornersImg_selected, onClick : {name:'background',position:2,sideMenuRenderElements:'BackgroundElement'}, selected:false, text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
                {id : "MenuMine", ref : "MenuMine", src : MineImg, src_selected : MineImg_selected, onClick : {name:'mine',position:3,sideMenuRenderElements:'MineElement'}, selected:false, text:this.props.current_lang === 'en' ? "Uploads" : '上載'},
            ];
            type = 'text';
        }else{
            if (this.state.side_menu_text) {
                side_menu_text = this.state.side_menu_text;
            } else {
                side_menu_text = 'TextElement';
            }

            side_menu_control=[
                {id : "MenuLayout", ref : "MenuLayout", src : LayoutImg, src_selected : LayoutImg_selected, onClick : {name:'layout',position:0,sideMenuRenderElements:'LayoutElement'}, selected:false, text:this.props.current_lang === 'en' ? 'Templates' : '模版'},
                {id : "MenuText", ref : "MenuText", src : TextImg, src_selected : TextImg_selected, onClick : {name:'text',position:1,sideMenuRenderElements:'TextElement'}, selected:false, text:this.props.current_lang === 'en' ? "Text" : '文字'},
                {id : "MenuImage", ref : "MenuImage", src : ImageImg, src_selected : ImageImg_selected, onClick : {name:'image',position:2,sideMenuRenderElements:'ImageElement'}, selected:false, text:this.props.current_lang === 'en' ? "Elements" : '素材'},
                {id : "MenuBackground", ref : "MenuBackground", src : CornersImg, src_selected : CornersImg_selected, onClick : {name:'background',position:3,sideMenuRenderElements:'BackgroundElement'}, selected:false, text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
                {id : "MenuMine", ref : "MenuMine", src : MineImg, src_selected : MineImg_selected, onClick : {name:'mine',position:4,sideMenuRenderElements:'MineElement'}, selected:false, text:this.props.current_lang === 'en' ? "Uploads" : '上載'},
            ];
        }
        // console.log('setSideMenu', {side_menu_control});
        // console.log('setSideMenu',side_menu_text?'TextElement':this.state.sideMenuRenderElements)
        this.setState({
            side_menu_control,
            side_menu_text,
            sideMenuRenderElements:side_menu_text?side_menu_control[0].onClick.sideMenuRenderElements:this.state.sideMenuRenderElements
        }, () => {
            this.SelectElement(type)
        });
    }

    closeMenu(){
      var self = this;
      if(this.state.menu_closed){
        self.props.showSideBar();
        self.setState({
          elementContainer : ["elementContainer"],
          element_select_menu : ["element_select_menu"],
          //closeMenu : ["closeMenu"],
          menu_closed : false,
        }, function(){
          self.props.updateDesignPanel("show");
        });
        this.refs.menu_back_arrow.src = CloseArrow;
        this.props.changeElementMenu();
      }else{
        self.props.closeSideBar();
        self.props.closeElementMenu();
        self.setState({
          elementContainer : ["hide"],
          element_select_menu : ["element_select_menu"],
          closeMenu : ["closeMenu"],
          menu_closed : true,
        });
      }
        this.props.clearAllPopup();
    }

    SelectElement(type = "layout"){
      if (!this._isMounted) {
        return;
      }
      var is_SideBar = this.state.is_SideBar;
      //console.log('SelectElement', type);
      if (is_SideBar) {

        // console.log(' sidebar', type);
        this.setState({
          elementContainer : ["elementContainer"],
          element_select_menu : ["element_select_menu"],
          closeMenu : ["closeMenu"],
          menu_closed : false,
          sideMenuRenderElements: null,
          MenuSelectedSelectorTop : -100,
        }, function(){
          this.props.updateDesignPanel("show");
        });

      }else{

        var position = [0,62,124,186,248];
        //console.log('not sidebar', type);


        if(this.state.menu_closed){ //hot fix cannot show hide menu
          this.closeMenu();
        }
        if (this.refs.menu_back_arrow) {
          this.refs.menu_back_arrow.src = CloseArrow;
        }


        this.setState({
          elementContainer : ["elementContainer"],
          element_select_menu : ["element_select_menu"],
          closeMenu : ["closeMenu"],
        }, function(){

            this.props.updateDesignPanel("show");

          //if (type.key){
            /*this.setState({
              sideMenuRenderElements : type.sideMenuRenderElements,
              MenuSelectedSelectorTop : position[type.position],
              current_type : type.name,
            });*/
          //}
          /*
          alert(type.name);
          alert(type.position);
          alert(type.key);
          */

          if(type==="text"){
              //console.log('sideMenuRenderElements text', this.state.side_menu_text?'TextElement':this.state.side_menu_control[0].onClick.sideMenuRenderElements);
            this.setState({
              sideMenuRenderElements : this.state.side_menu_text?'TextElement':this.state.side_menu_control[0].onClick.sideMenuRenderElements,
              MenuSelectedSelectorTop : position[0],
              current_type : type,
            });
          }
          /*else if(type=="image"){
            this.setState({
              sideMenuRenderElements : <ImageElement key="image_element" />,
              MenuSelectedSelectorTop : position[2],
              current_type : type,
            });
          }else if(type=="background"){
            this.setState({
              sideMenuRenderElements : <BackgroundElement key="background_element" />,
              MenuSelectedSelectorTop : position[3],
              current_type : type,
            });
          }else if(type=="layout"){
            this.setState({
              sideMenuRenderElements : <LayoutElement key="layout_element" />,
              MenuSelectedSelectorTop : position[0],
              current_type : type,
            });
          }else if(type=="mine"){
            this.setState({
              sideMenuRenderElements : <MineElement key="mine_element" />,
              MenuSelectedSelectorTop : position[4],
              current_type : type,
            });
          }*/
          else {
              //console.log('sideMenuRenderElements else', type, type.sideMenuRenderElements, type.sideMenuRenderElements?'LayoutElement':type.sideMenuRenderElements);
              this.setState({
                  sideMenuRenderElements : type.sideMenuRenderElements?type.sideMenuRenderElements:(this.state.sideMenuRenderElements?this.state.sideMenuRenderElements:'LayoutElement'),
                  MenuSelectedSelectorTop : position[type.position?type.position:0],
                  current_type : type.name?type.name:"layout",
              });
          }
        });
      }
      this.props.setDefaultMode();
      this.props.showImageFilter('sidebar');
      this.props.clearAllPopup();
    }

    changeMyFavouriteFilter(){
      this.props.setMyFavouriteFilter();
    }

    render() {
        //console.log('rerender sideMenuControls', this.state.images, this.state.sideMenuRenderElements);
        const sideMenuControls = this.state.side_menu_control.map((element, index) =>
          (<SideMenuControl key={"side_menu_"+index} id={element.id} ref={element.ref} src={(element.selected?element.src_selected:element.src)}
              click={this.SelectElement.bind(this, element.onClick)} selected={element.selected} text={element.text}/>)
        );

        const myFavouriteControl = (this.state.current_type!="mine" && this.props.authStatus=="member"? (
            <div className="sideMenuFilter">
              <img src={(this.props.myFavouriteFilter?star_selected:star_unselected)} onClick={this.changeMyFavouriteFilter}/>
              <span onClick={this.changeMyFavouriteFilter}>My Favourite Only</span>
            </div>
          ) : "");

        return (
          <div className="side_menu">
            {this.state.side_menu_control && this.state.side_menu_control.length >0 ?
            <div className={classNames(["side_control_panel_wrapper", "dummy_side_control_panel_wrapper_style"])}>
              <div className={classNames(this.state.element_select_menu)}>
                {sideMenuControls}
                <div className="MenuSelectedSelector" style={{top:this.state.MenuSelectedSelectorTop+'px'}} ref="MenuSelectedSelector"></div>
              </div>
              <div className={classNames(this.state.elementContainer)} ref="element_container">
                {myFavouriteControl}
                <div className="menu_container" ref="menu_container">
                  {this.sideMenuRenderElements(this.state.sideMenuRenderElements)}
                  <div key="loader sideMenu" className={classNames(this.state.loading)} id="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
              </div>
            </div>
            :""}
            <div className={classNames(this.state.closeMenu)} onClick={this.closeMenu.bind(this)}><img ref="menu_back_arrow" className="menu_back_arrow" src={CloseArrow}/></div>

          </div>
        )
    }

/*init:

prop:
  handleLoadTemplate

state:
  activePanel

function:
  showTemplatePanel:
    set activePanel to templatePanel
  showElementPanel:
    set activePanel to elementPanel
  showTextPanel:
    set activePanel to textPanel
  showBackgroundPanel:
    set activePanel to backgroundPanel
  showUploadPanel:
    set activePanel to uploadPanel

render:
  - (button onClick:showTemplatePanel)Templates
  - (button onClick:showElementPanel)Elements
  - (button onClick:showTextPanel)Text
  - (button onClick:showBackgroundPanel)Background
  - (button onClick:showUploadPanel)Uploads
  - SideTemplatePanel(handleLoadTemplate)
  - SideImagePanel(handleLoadTemplate)
  - SideTextPanel(handleLoadTemplate)
  - SideBackgroundPanel(handleLoadTemplate)
  - SideUploadPanel(handleLoadTemplate)
*/
}

const mapStateToProps = state => ({
    current_lang: state.current_lang,
    authStatus: state.authStatus,
    myFavouriteFilter: state.myFavouriteFilter,
    payment_term: state.payment_term,
    select_from_team_id:state.select_from_team_id,
    team:state.team,
    project_target: state.project_target
});

const mapDispatchToProps = dispatch => ({
  //uploadMine: (files, callback) => dispatch(uploadMine(files, callback)),
  showImageFilter: (val) => dispatch(showImageFilter(val)),
  setDefaultMode: () => dispatch(setDefaultMode()),
  clearAllPopup: () => dispatch(clearAllPopup()),
  closeSideBar: () => dispatch(closeSideBar()),
  showSideBar: () => dispatch(showSideBar()),
  setMyFavouriteFilter: () => dispatch(setMyFavouriteFilter()),
  closeElementMenu: () => dispatch(closeElementMenu()),
  changeElementMenu: () => dispatch(changeElementMenu()),
  setPaymentTerm: (payment_term) => dispatch(setPaymentTerm(payment_term)),
  setSelectFromTeamID: (select_from_team_id,team) => dispatch(setSelectFromTeamID(select_from_team_id,team)),
  setProjectTarget: (project_target) => dispatch(setProjectTarget(project_target)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    )(SideControlPanel);
