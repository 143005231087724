import React from 'react';
//import Cookies from 'universal-cookie';
//import { connect } from 'react-redux';
//import classNames from "classnames";

//css and images
import './progress_bar.scss';
import '../Home/JD Homepage-09_cc-09.svg';

import ProgressBarFilter from './ProgressBarFilter';

class ProgressBarComponent extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {

        };

    }

    render() {
        return (
            <div className="progress_bar">
                <ProgressBarFilter percentage={this.props.percentage}/>
            </div>
        )
    }
}

export default ProgressBarComponent;
