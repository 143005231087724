//import { delay } from 'redux-saga'
import { put, takeEvery, all, select, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
// import { makeid } from '../utilities/general'
import Cookies from 'universal-cookie';

//const MOCK_NETWORK_DELAY = 1000;

function* saveProject(action) {
  yield put({ type: 'SET_PROJECT_SAVING', isSaving: true })
  let data = action.data;
  let saveId = action.saveId;
  let projectName = action.projectName;
  let width = action.width;
  let height = action.height;
  let permission = action.permission;
  let fonts_json = action.fonts_json;
  let brand_token = action.brand_token;
  let partnership_link = action.partnership_link;
  let recentlyUsedImages = action.recentlyUsedImages;
  let recentlyUsedPhotos = action.recentlyUsedPhotos;
  let callback = action.callback;

  const state = yield select();

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var unit = cookies.get('unit');
  var custom_width = cookies.get("custom_width");
  var custom_height = cookies.get("custom_height");
  var success = false;
  var errorMessage = '';

  if(permission=="read"){
    yield put({ type: 'SET_PROJECT_SAVING', isSaving: false })
    yield put({ type: 'SET_SAVE_ID', saveId: saveId })
    return "";
  }
  // let data = this.refs.designPanelRef.exportData();
  /*if (window.self !== window.top) {
    if(callback && typeof callback == 'function'){
      callback();
    }
    return;
  }*/
  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/save`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "api_token" : api_token,
      "json" : JSON.stringify(data),
      "project_name" : projectName,
      "width" : width,
      "height" : height,
      "custom_unit": unit,
      "custom_width": custom_width,
      "custom_height": custom_height,
      "fonts_data": JSON.stringify(fonts_json),
      "brand_token" : JSON.stringify(brand_token),
      "partnership_link" : partnership_link,
      "recentlyUsedImages": JSON.stringify(recentlyUsedImages),
      "recentlyUsedPhotos":JSON.stringify(recentlyUsedPhotos),
      "productOptions":JSON.stringify(state.currentProjectOptions),
      "lang": state.current_lang=='zh'?state.current_lang:'en'
    })
  })
  .then(function(response) {
    if(response.status==404 && api_token) {
        return {status: 'Y'}
    } else if (response.status>399) {
        response.clone().text().then(data => console.error(JSON.stringify(data), JSON.stringify(response), api_token, code));
    }
    return response.json();
  })
  .then(function(myJson) {
    if(myJson.status==="Y"){
      success = true;
    } else {
      //cookies.remove('api_token');
      //cookies.remove('code');
      //console.log('saving fail, goto logout', myJson);
      //window.location = `${process.env.REACT_APP_API_HOST}`;
      //window.location = `${process.env.REACT_APP_API_HOST}/ajax/logout`;
      //console.log("Save Error");
      //alert("Error");
        errorMessage = 'Cannot get write permission';
        if (myJson && myJson.msg)
        {
            errorMessage = myJson.msg;
        } else if (myJson.errors) {
            errorMessage = Object.values(myJson.errors).map(els => els.join(', ')).join(', ');
        } else if (myJson.message) {
            errorMessage = myJson.message;
        } else {
            // Sentry log
            console.error(JSON.stringify(myJson), errorMessage);
            return myJson;
        }
    }
    return myJson;
  }).catch(e => {
    console.log(JSON.stringify(e)); // , e.message, JSON.stringify(e)
    //return e;
  });
  yield put({ type: 'SET_PROJECT_SAVING', isSaving: false })
  if (success) {
    yield put({ type: 'SET_SAVE_ID', saveId: saveId });
    if(callback && typeof callback == 'function'){
      callback();
    }
  }
  else if (errorMessage) {
      yield put({ type: 'SET_SAVE_ERROR', saveError: errorMessage });
  }
}

function* createProject(data){

  //console.log("create project");
  // var self = this;
  const cookies = new Cookies();
  let code = cookies.get('code');
  if(!code){
      //console.log("NEW API_TOKEN");

      fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/create`, {
        method : "POST",
        credentials: 'include',
        headers : {
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
        },
        body: JSON.stringify({}),
      })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        //console.log("NEW SUCCESS");
        //console.log(myJson);
        if(!myJson[0] && 'object' === typeof myJson) {
            myJson = [
                myJson
            ];
        }

        if(cookies.get('code')!=myJson[0].code) {
            const api_token = cookies.get('api_token');
            const token = myJson[0].api_token?myJson[0].api_token:api_token
            if (!api_token && api_token!=='guest') {
                cookies.set('api_token', token, { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
            }
            window.location.href = '/design-builder/' + myJson[0].code + '/' + token;
            //cookies.set('code', myJson[0].code, { path: '/design-builder/' + myJson[0].code  });
            return;
        }
        if(cookies.get('api_token')!==myJson[0].api_token)
        {
            cookies.set('api_token', myJson[0].api_token, { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
        }
        code = myJson[0].code;
        //console.log("create call back");
        data.callback(code);
      }).catch(e => {
        console.log(e);
        return e;
      });
      yield put({ type: 'SET_PROJECT_CODE', projectCode: code })
  }else{
      //console.log("LOAD API_TOKEN");
      //console.log("api_token: "+cookies.get('api_token'));
      //console.log("code: "+cookies.get('code'));
      //self.loadCurrent();
      data.callback();
  }
}

function* checkUserVerifyStatus(data){
  // var self = this;

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var verifyStatus,verifyNickname, verifyEmail = null;
  // var response_json = {};
  yield fetch(`${process.env.REACT_APP_API_HOST}/design/auth/` + code + '/' + api_token, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    if (myJson.status == 'Y') {
      verifyStatus=myJson.verify;
      verifyNickname = myJson.verify_nickname;
      verifyEmail = myJson.verify_email;
    } else {
      verifyStatus='0';
    }
  }).catch(e => {
    console.log(e);
    return e;
  });
  yield put({ type: 'SET_VERFIFY_STATUS', verifyStatus, verifyNickname, verifyEmail});
  data.callback("done");

}

function* checkUserSession(){
  // var self = this;

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var authStatus = null;
  var verifyStatus = null;

  // var response_json = {};
  yield fetch(`${process.env.REACT_APP_API_HOST}/design/auth/` + code + '/' + api_token, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    if (myJson.status == 'Y') {
      authStatus = 'member';
      verifyStatus=myJson.verify;
    } else {
      authStatus = 'guest';
      verifyStatus='0';
    }
    //console.log("1: "+authStatus);
    //console.log("3: "+verifyStatus);
  }).catch(e => {
    console.log(e);
    return e;
  });
  //console.log("2: "+authStatus);
  yield put({ type: 'SET_AUTH_STATUS', authStatus });
  yield put({ type: 'SET_VERFIFY_STATUS', verifyStatus });
}

function* auth(data){
  const cookies = new Cookies();
  var api_token = data.token;

  // var response_json = {};
  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/auth`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
        "token" : api_token
    })
  })
  .then(function(response) {
    var api_token = cookies.get('api_token');
    if(response.status==403 && (!api_token || api_token=='guest')) {
        cookies.set('api_token', data.token?data.token:'guest', { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
        return {};
    }
    if(response.status==410 && !api_token) {
        return {};
    }
    else if(response.status!=200 && !api_token) {
        return {};
    }
    return response.json();
  })
  .then(function(myJson) {
     var api_token = cookies.get('api_token');
     if (myJson.token) {
         cookies.set('api_token', myJson.token, { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
     } else if (!api_token) {
         cookies.set('api_token', 'guest', { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
     }
    data.callback()
  }).catch(e => {
    var api_token = cookies.get('api_token');
    if(!api_token)
    {
        cookies.set('api_token', data.token?data.token:'guest', { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
    } else if(api_token == 'guest' && data.token) {
        cookies.set('api_token', data.token, { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
    }
    console.log(e);
    return data.callback();
  });
}

function promptLogin(action){
  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  let callback = action.callback;

  let popupWindow = null;

  let checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
          popupWindow = null;
          clearInterval(checkWindowClosed);
          callback("Failed");
      }
  }, 1000);

  var listening = () => {
      if (popupWindow) {
          window.addEventListener('message', event => {
              let is_success = false
              if (event.origin.indexOf(`${process.env.REACT_APP_API_HOST}`) !== -1) {
                  // console.log('postMessage: '+ JSON.stringify(event.data));
                  if (event.data.action == 'authCallback') {
                      //console.log("Auth callback B: " + event.data.data.status)
                      if (popupWindow) {
                          popupWindow.close();
                          popupWindow = null;
                          clearInterval(checkWindowClosed);
                      }
                      callback(event.data.data.status);
                      if (event.data.data.status == 200) {
                          put({type: 'SET_AUTH_STATUS', authStatus: 'member'});
                          if (event.data.verifyStatus !== undefined)
                          {
                              // console.log('SET_VERFIFY_STATUS', {...event.data});
                              /**
                               * @FIXME replace photoUrl Storage::disk('s3')->move('uploads/'.$api_token, 'uploads/'.session('username'));
                               */
                              put({ type: 'SET_VERFIFY_STATUS', ...event.data});
                          }
                      }
                      is_success = true
                  }
              }
              if(!is_success) {
                  listening();
              }

          }, {once: true});
      }
  }
  popupWindow = window.open(`${process.env.REACT_APP_API_HOST}` + '/design/login/'+code+'/'+api_token, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
  listening();
}

function promptSlackLogin(action){
  const cookies = new Cookies();
  // var code = cookies.get('slack_code');
  cookies.remove('slack_code');
  let callback = action.callback;

  let popupWindow = null;

  let checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
          var code = cookies.get('slack_code');
           popupWindow = null;
          clearInterval(checkWindowClosed);
          if (!code) {
              callback("Failed");
          } else {
            callback('OK');
          }
      }
  }, 1000);
  popupWindow = window.open('https://slack.com/oauth/authorize?client_id=576304338454.575941323991&scope=users.profile:read,files:write:user,channels:read,groups:read','Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
}

function* loadProject(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = data.code ? data.code : cookies.get('code');
  var access_level = -1;
  var username = 0;
  var permission = "";
  var payment_term ="";
  var project_target="";
  var product_name = "";
  var product_id = "";
  var custom_fonts = "";
  var custom_colors = "";
  //var brand_token = "";
  var brand_kit = "";
  var load_fonts_project = false;
  var recentlyUsedImages = [];
  var recentlyUsedPhotos = [];
  var hidePageOption = false;
  var max_page = null;
  var min_page = null;

  var is_customise_further=false;
  var team = [];
  /*
  var projectOptions = { // TODO: move to API
    options: [{
      name: 'color',
      displayName: 'Color',
      variations: [{
        'id': 1,
        'name': 'grey',
        'displayName': 'Grey',
      }, {
        'id': 2,
        'name': 'red',
        'displayName': 'Red',
      }, {
        'id': 3,
        'name': 'green',
        'displayName': 'Green',
      }, {
        'id': 4,
        'name': 'blue',
        'displayName': 'Blue',
      }],
    }, {
      name: 'size',
      displayName: 'Size',
      variations: [{
        'id': 1,
        'name': 'S',
        'displayName': 'S',
      }, {
        'id': 2,
        'name': 'L',
        'displayName': 'L',
      }],
    }],
    images: [{
      'color': 'grey',
      'size': 'S',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
    }, {
      'color': 'grey',
      'size': 'L',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/grey_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
    }, {
      'color': 'red',
      'size': 'S',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }]
    }, {
      'color': 'red',
      'size': 'L',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/red_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
    }, {
      'color': 'green',
      'size': 'S',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
    }, {
      'color': 'green',
      'size': 'L',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/green_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
    }, {
      'color': 'blue',
      'size': 'S',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_s.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 145,
          left: 271,
          width: 182,
          height: 210
        },
      }],
    }, {
      'color': 'blue',
      'size': 'L',
      'canvas': [{
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
      'previews': [{
        'design_page': 0,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }, {
        'design_page': 1,
        'design_background': 'https://myrescc.s3.ap-east-1.amazonaws.com/blue_l.png',
        'design_background_width': 700,
        'design_background_height': 700,
        'design_canvas_location': {
          top: 94,
          left: 241,
          width: 240,
          height: 335
        },
      }],
    }]
  };
  */
  var projectOptions = [];
  var currentProjectOption = null;
  //hidePageOption = true; // TODO: move to API
  // console.log("api_token: "+api_token);
  // console.log("code: "+data.code, {code}, cookies.get('code'));
  var response_json = {};
  const state = yield select();

  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "api_token" : api_token,
      "code" : code,
      "lang": state.current_lang=='zh'?state.current_lang:'en'
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
      if(myJson.status!=="E"){
        access_level = myJson.access_level;
        username = myJson.username;
        permission = myJson.permission;
        payment_term= myJson.payment_term;
        projectOptions=myJson.projectOptions;
        var fonts = [], colors = [];//, brand_token_list= [];
        var has_watermark=true;
        var is_printable =false;
       /* for (var i =0; i < myJson.brand_kit.length; i++) {
            var fonts_list = JSON.parse(myJson.brand_kit[i].brand_fonts);
            for (var j =0; j < fonts_list.length; j++) {
                fonts.push(fonts_list[j]);
            }
            var color_list = JSON.parse(myJson.brand_kit[i].brand_colors);
            for (var j =0; j < color_list.length; j++) {
                colors.push(color_list[j]);
            }
            if (fonts_list.length > 0) {
                brand_token_list.push(myJson.brand_kit[i].token);
            }

        }*/
        custom_fonts = fonts ? fonts : [];
        custom_colors = colors ? colors : [];
        //brand_token = brand_token_list ? brand_token_list : [];
        brand_kit= myJson.brand_kit;
        load_fonts_project = myJson.load_project_fonts;
        recentlyUsedImages = myJson.recentlyUsedImages ? myJson.recentlyUsedImages : [];
        recentlyUsedPhotos = myJson.recentlyUsedPhotos ? myJson.recentlyUsedPhotos : [];

        if (myJson.has_watermark==0){
          has_watermark=false;
        }
        if (payment_term=="monthly"){
          has_watermark=false;
        }
        if (myJson.is_printable==1){
          is_printable=true;
        }
        if (myJson.is_customise_further==1){
          is_customise_further=true;
        }
        let project_name = myJson.items[0].project_name;
          var items = myJson.items;
          if(items.length>0){
          var item = items[0];
          if(item !== null && item !== ""){
            if (item.options) {
              projectOptions = item.options;
            }
            if (item.default_variation_data) {
              currentProjectOption = JSON.parse(item.default_variation_data);
            }
            if (item.hidePageOption) {
              if (item.hidePageOption==1 ){
                hidePageOption = true;
              }
            }
            if (item.max_page) {
              max_page=item.max_page;
            }
            if (item.min_page) {
              min_page=item.min_page;
            }
            project_target = item.target;
            product_name = item.product_name;
            product_id = item.product_id;
            var callback_url = "";
            if(item.callback != null){
              callback_url = item.callback;
            }
            var partnership_link = "";
            if(item.partnership_link != null){
              partnership_link = item.partnership_link;
            }
            var select_from_team_id=0;
            if(item.select_from_team_id != null){
              select_from_team_id = item.select_from_team_id;
            }
            if(item.team != null){
              team = item.team;
            }
            if(item.json !== null  && item.json !== ""){
                response_json = item.json.pages;
                data.callback(
                  response_json, access_level, project_name, username, permission, product_name, custom_fonts, custom_colors,
                  brand_kit, load_fonts_project,has_watermark,is_printable, callback_url, partnership_link, item.main_url, item.save_url, recentlyUsedImages, recentlyUsedPhotos,project_target,payment_term,select_from_team_id, team, item.partnership_param );
            }else{
              data.callback(
                null, access_level, project_name, username, permission, product_name, custom_fonts, custom_colors,
                brand_kit, load_fonts_project,has_watermark,is_printable, item.callback, partnership_link, "", "", recentlyUsedImages, recentlyUsedPhotos,project_target,payment_term,select_from_team_id, team, item.partnership_param );
            }
          }
        }
      }else{
          //console.log("api_token: "+api_token+", code: "+code);
          //console.log(myJson);
          //cookies.remove('api_token');
          //cookies.remove('code');
          // self.createProject();
          // return;
          //window.location = `${process.env.REACT_APP_API_HOST}/`;
          if(cookies.get('code')!==code)
          {
              cookies.remove('code');
          }
          window.location.href = `${process.env.REACT_APP_MARKETPLACE_API_HOST}`;
      }

  }).catch(e => {
    console.log(e);
    return e;
  });
  //yield put({ type: 'SET_ACCESS_LEVEL', access_level: access_level })
  yield put({ type: 'SET_PROJECT_OPTIONS', projectOptions: projectOptions })
  yield put({ type: 'SET_PROJECT_CODE', projectCode: code })
  yield put({ type: 'SET_PROJECT_PRODUCT_ID', productId: product_id })
  yield put({ type: 'SET_CUSTOMISE_PREVIEW', is_customise_further: is_customise_further })

  yield put({ type: 'SET_HIDE_PAGE_OPTION', hidePageOption, max_page, min_page  })
  if (max_page==2 && min_page==1){
    yield put({ type: 'SET_PRESET_PRINT_DESIGN_OPTION' })
  }
  if (currentProjectOption) {
    for (var key in currentProjectOption) {
      var value = currentProjectOption[key];
      if (key=="print_design"){
        yield put({ type: 'SET_PRINT_DESIGN_OPTION', option: key, variation: value })
      } else{
        yield put({ type: 'SET_CURRENT_PROJECT_OPTION', option: key, variation: value })
      }
    }
  } else if (projectOptions && projectOptions.options && projectOptions.options.length) {
    for (var i in projectOptions.options) {
      let option = projectOptions.options[i];
      yield put({ type: 'SET_CURRENT_PROJECT_OPTION', option: option.name, variation: option.variations[0].name })
    }
  }

  //yield put({ type: 'LOAD_CANVAS_DATA', data: response_json })

}

function loadPrintOnly(data/*, callback*/){

  //var self = this;
  //var code = code;
  var response_json = {};

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  console.log("code: "+data.code, api_token);
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "api_token" : api_token,
      "code" : data.code
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {

      if(myJson.status!=="E"){
          var items = myJson.items;
              if(items.length>0){
              var item = items[0];
              if(item !== null && item !== ""){
                  if(item.json !== null  && item.json !== ""){
                      response_json = item.json.pages;
                      data.callback(response_json);
                  }
              }
          }
      }else{
          console.log("Fail to get project");
          data.callback(null);
          const cookies = new Cookies();
          cookies.remove('api_token');
          cookies.remove('code');
          //self.createProject();
      }

  }).catch(e => {
    console.log(e);
    return e;
  });


}

function* exportProject(data){

  var param = data.param;

  //const cookies = new Cookies();
  //var api_token = cookies.get('api_token');
  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: true })
  yield put({ type: 'CLEAR_ACTIVE_ELEMENT' })
  const state = yield select();
  var saveProjectData = {};
  saveProjectData.pages = JSON.parse(JSON.stringify(state.pages));
  //yield saveProject({ type: 'SAVE_PROJECT', data: saveProjectData, saveId: state.currentSaveId, projectName: state.project_name, permission: state.permission});
  var exportAction="";
  if (param.action=="print"){ exportAction ="?exportAction=print";}

    let status = 0;
    let retry = 0;
    window._loq = window._loq || [];
    window._loq.push(['tag', 'exporting']);
    for(var i = 1; i <= 10; i++) {
        yield fetch(`${process.env.REACT_APP_API_HOST}/design/export/`+param.width+'x'+param.height+'/'+param.code+'/'+param.token+'/'+param.type+exportAction, {
            method : "GET",
            headers : {
                "Access-Control-Allow-Origin" : "*",
                'Accept': 'application/json',
                'X-Requested-With' : 'XMLHttpRequest',
                "Content-Type" : "application/json;charset=UTF-8", //must add this line to tell server you are using JSON
            },
        })
            .then(function(response) {
                status = response.status;
                retry = response.headers.get('retry-after');
                return response.json();
            })
            .then(function(myJson) {
                /*if(status>=400)
                {
                    console.log(data.callback?data.callback.toString():data.callback)
                }*/
                if(status<400)
                {
                    data.callback(myJson);
                }
            }).catch(e => {
                if (!(status==429 || status==503 || status==518))
                {
                    console.error(JSON.stringify(e.message), status); // 500 returned here
                }
                data.callback({error:status});
                return {};
            });
        if((status==429/* || status==503*/ || status==518) && i <= 10) {
            if(retry===null) {
                if(status==503 && i>1) {
                    retry = 55;
                } else {
                    retry = 25;
                }
            }
            // console.log({delay: retry * 1000 + 10});
            window._loq.push(['tag', 'downloadretry']);
            yield delay(retry * 1000 + 10) // use retry after
        }
        else if((status==429 || status==503 || status==518)) {
            data.callback({error:'Exporting Timeout'});
            return;
        }
        else {
            window._loq.push(['tag', 'downloading']);
            yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: false });
            if (status>0)
            {
                //cannot call data.callback({error:status});
                return;
            } else {
                break;
            }
        }
    }
}

function requestSignUrl(type) {
    const cookies = new Cookies();
    return fetch(`${process.env.REACT_APP_API_HOST}/mygallery`, {
        method: 'post',
        headers : {
            "Access-Control-Allow-Origin" : "*",
            //'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
        },
        body:JSON.stringify({
            'contentType': type,
            'api_token': cookies.get('api_token')
        })

    }).then(response => {
        return response.json();
    }).then(json => {
        if(json.api_token) {
            cookies.set('api_token', json.api_token, { path: '/', domain: `${process.env.REACT_APP_CROSS_DOMAIN_COOKIE}`});
        }
        return json;
    })
}

function uploadMine(data){

    //var files = data.files;

    let file = data.file
    requestSignUrl(file.type).then(res => {
        var formData = new FormData();
        for(let index in res.data) {
            formData.append(index, res.data[index]);
        }
        formData.append('Content-Type', file.type);
        formData.append('acl', 'public-read');
        formData.append("file", file);

        return fetch(res.action, {
            method: 'post',
            body: formData,
            timeout: 10000,


        })
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then((/*aws_res*/) => {data.callback({'original': res.action + res.data.key})})
            .catch(e => alert(e));
    }).catch(e => {console.log(e);alert('Unable to acquire permission');data.fail && data.fail(e);})



}

function* getDesignElement(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  var state = yield select();
  var myFavouriteFilter = state.myFavouriteFilter;

  var brand_token_list = data.brand_token && data.brand_token.length > 0 ? data.brand_token : "";
  var json_data = {
      "type":data.element_type,
      "api_token":api_token,
      "offset":data.offset,
      "limit":data.limit,
      "image_type":data.image_type,
      "code" : code,
      "my_favourite": myFavouriteFilter,
  };
  if(data.category_id)
  {
      json_data.category_id = data.category_id
  }
  if(data.keyword)
  {
      json_data.keyword = data.keyword
  }
  if (brand_token_list)
  {
      json_data.brand_token_list = data.brand_token_list
  }
  if(data.other.width != undefined && data.other.height != undefined){
    json_data.width = data.other.width;
    json_data.height = data.other.height;
  }

  let url = `${process.env.REACT_APP_API_HOST}/ajax/design/getDesignElement`;
  let params = {
      cache: 'no-cache', // force-cache
      headers : {
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json;charset=UTF-8",
          "Accept": "application/json", //must add this line to tell server you are using JSON
      },
      // body: JSON.stringify(json_data),
  };

    url += '?_'+data.hash+'&' + ( new URLSearchParams( json_data ) ).toString(); //_'+data.hash+'&
    params.method = 'GET';

  yield fetch(url, params)
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    // dev code
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return {};
  });

}

function getRecentlyUsedImages(data) {
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getRecentlyUsedImages`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "username":data.user_id,
      "category_id": data.category_id,
      "offset" : data.offset1,
      "limit": data.limit1
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function saveElementId(data){
  /*if (window.self !== window.top) {
    if(data.callback && typeof data.callback == 'function'){
      data.callback({});
    }
    return;
  }*/
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/saveElementId`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "username":data.user_id,
      "elementId":data.element_id,
      "photos_element_ids": data.photo_element_ids
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function getDesignCategory(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getDesignCategory`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "image_type":data.category_id,
      "type":data.element_type,
      "offset":data.offset,
      "limit":data.limit,
      "brand_token_list" : data.brand_token,
      "api_token":api_token,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function* saveDesignerTemplate(data){

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: true });

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');

  /*if (window.self !== window.top) {
    if(data.callback && typeof data.callback == 'function'){
      data.callback({});
    }
    return;
  }*/
  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/saveDesignerTemplate`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code": data.code,
      "page": data.page,
      "type": data.saveType,
      "api_token": api_token,
      "width" : data.width,
      "height": data.height,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

  yield put({ type: 'SET_IS_DOWNLOADING_OUTFILE', isDownloadingOutfile: false });
}

function bindProject(data){
  //console.log("bindProject");
  //console.log(data);
  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  //var url = "http://127.0.0.1:6999/ajax/design/bind/"+code+"/"+api_token;
  var url = `${process.env.REACT_APP_API_HOST}/ajax/design/bind/`+code+"/"+api_token;
  //console.log(url);
  data.callback(url);

}

function savePreview(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  /*if (window.self !== window.top) {
    if(data.callback && typeof data.callback == 'function'){
      data.callback({});
    }
    return;
  }*/
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/savePreview`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "size" : data.width+"x"+data.height,
      "code" : code,
      "token" : api_token,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    console.log(myJson);
    data.callback();
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectShare(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/setProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "token" : api_token,
      "email" : data.email,
      "permission" : data.permission,
      "scope" : data.scope,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectShareTeam(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/setProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "token" : api_token,
      "team_id" : data.team_id,
      "permission" : data.permission,
      "scope" : data.scope,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getProjectShare(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/getProjectShare`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "token" : api_token,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getFolderList(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/get-folders`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "token" : api_token,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function setProjectFolder(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var folder_id = data.folder_id;

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/set-project-folder`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "code" : code,
      "token" : api_token,
      "folder_id" : folder_id,
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function* setMyFavourite(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var element_id = data.element_id;
  var star = data.star;
  //alert("setMyFavourite: "+element_id+", "+star);

  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/set-my-favourite`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "token" : api_token,
      "element_id" : element_id,
      "star" : star,
    })
  })
  .then(function(response) {
    return response.json();
  })
  /*.then(function(myJson) {
    //data.callback(myJson);
  })*/.catch(e => {
    console.log(e);
    return e;
  });
    yield put({ type: 'SET_MY_FAVOURITES', element_id });

}

function checkSocialMediaToken(data){
  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  //var code = cookies.get('code');
  var media_id = data.media_id;
  var action = "check"
  if (media_id) {
    action = "add"
  }

    var url = `${process.env.REACT_APP_API_HOST}/ajax/design/getSocialMediaToken`

  fetch(url, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "token" : api_token,
      "type": data.media_type,
      "social_media_id" : media_id,
      "action" : action
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function slackUploadFile(data){
  const cookies = new Cookies();
  //var api_token = cookies.get('api_token');
  var code = cookies.get('code');

  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/slack_upload_file`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
     "code" : code,
      "token" : data.token,
      "text":  data.text,
      "channels": data.channels,
      "title": data.file_title
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function getCustomFonts(data){
  var username = data.username;

  fetch(`${process.env.REACT_APP_API_HOST}/fonts/get/` + username, {
    method : "GET",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    }
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function* createOrder(action) {
  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  const state = yield select();
  // var clientToken = null;
  // var createdOrder = null;
  if (state.checkout.isCreatingOrder) return;
  yield put({ type: 'SET_IS_CREATING_ORDER', isLoading: true })

  var product = state.productList ? state.productList.find(product => product.width == (state.pages.length ? state.pages[0].width : 0) && product.height == (state.pages.length ? state.pages[0].height : 0)) : null;
  if (!product) {
    product = state.productList[0];
  }
  if (!product) {
    return;
  }
  let orderDetails = {}, ordercode = null;
  orderDetails.productId = product.id;
  if (state.checkout.currencyOption.name) {
    orderDetails.currency = state.checkout.currencyOption.name;
  }
  orderDetails.variations = {};
  if (state.checkout.variations) {
    Object.keys(state.checkout.variations).forEach(key => {
      orderDetails.variations[key] = state.checkout.variations[key].value;
    })
  }
  if (state.checkout.shippingMethod) {
    orderDetails.shippingMethod = state.checkout.shippingMethod.value;
  } else {
    orderDetails.shippingMethod = 'standard';
  }

  orderDetails.address = state.checkout.address;
  orderDetails.printFile = state.checkout.printFile;
  //orderDetails.pageCount = orderDetails.variations.printing_side == 'double' ? Math.ceil(state.pages.length / 2) : state.pages.length;
  let hasCommonBack = false;
  state.pages.forEach(page => {
    if (page.commonBack) {
      hasCommonBack = true;
    }
  })

  orderDetails.pageCount = (hasCommonBack && orderDetails.variations.printing_side == 'double') ? (state.pages.length - 1) : (orderDetails.variations.printing_side == 'double' ? Math.ceil(state.pages.length / 2) : state.pages.length);

  orderDetails.projectCode = code;
  if (state.checkout.priceOption) {
    orderDetails.copy = state.checkout.priceOption.value;
  }

  /*if (window.self !== window.top) {
    if (action.callback) {
      action.callback();
    }
    return;
  }*/

  if(!api_token){
      console.error('error api_token empty',action.select_from_team_id);
  }
  yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/payment`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json", //must add this line to tell server you are using JSON
    },
    body: JSON.stringify({
      "token" : api_token,
      "payment_amount" : action.paymentAmount,
      "payment_method_nonce" : action.paymentNonce,
      "payment_term" : action.payment_term,
      "select_from_team_id" : action.select_from_team_id,
      "order_details" : orderDetails
    })
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(transactiondata) {
    //console.log(transactiondata);
    //console.log(transactiondata.status);
    //console.log(transactiondata.msg);
    if (transactiondata.status=="success"){
      ordercode=transactiondata.ordercode;
      //console.log(ordercode);
      if (action.callback) {
        action.callback();
        alert('payment success! A confirmation email has been sent to your email '+transactiondata.email);
        if (transactiondata.team_token!=""){
          window.location = `${process.env.REACT_APP_API_HOST}/team/info/${transactiondata.team_token}?tab=team_orders`;
        }else{
          window.location = `${process.env.REACT_APP_API_HOST}/account?page=print`;
        }

      } else {
        alert('payment success');
      }
    } else {
      if (action.callback) {
        action.callback(new Error(transactiondata.msg));
      } else {
        alert('payment fail');
      }
    }
  }).catch(e => {
    if (action.callback) {
      action.callback(new Error('payment fail'));
    } else {
      alert('payment fail');
    }
    console.log('error: '+e);
    return e;
  });

  yield put({ type: 'SET_CREATED_ORDER', ordercode })
  yield put({ type: 'SET_IS_CREATING_ORDER', isLoading: false })

}

function* fetchProductList(action) {
  var lang = action.data;
  const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var productList = [];
  // TODO
   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_product_list`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
       "Accept": "application/json",
     },
     body: JSON.stringify({
      "lang" : lang,
      "code" : code,
    })
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     productList = myJson.products;
   }).catch(e => {
     console.log(e);
     return e;
   });
  yield put({ type: 'SET_PRODUCT_LIST', productList });
}

function* fetchShippingCountries(action) {
  var lang = action.lang;
  // const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  var shippingCountries = [];

  // TODO

   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_delivery_options`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
       "Accept": "application/json",
     },
     body : JSON.stringify({
      "lang" : lang,
     })
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     shippingCountries = myJson.shippingCountries;
   }).catch(e => {
     console.log(e);
     return e;
   });

  /*
  yield new Promise(resolve => {
    setTimeout(() => {
      shippingCountries = [
        {
          value: 'cn',
          name: 'China',
          shippingMethods: [
            {
              value: 'standard',
              name: 'Standard Shipping',
            }
          ]
        },
        {
          value: 'hk',
          name: 'Hong Kong',
          shippingMethods: [
            {
              value: 'standard',
              name: 'Standard Shipping',
            },
            {
              value: 'express',
              name: 'Express Shipping',
            }
          ]
        },
        {
          value: 'us',
          name: 'United State',
          shippingMethods: [
            {
              value: 'standard',
              name: 'Standard Shipping',
            },
            {
              value: 'express',
              name: 'Express Shipping',
            }
          ]
        },

      ];
      resolve();
    }, MOCK_NETWORK_DELAY);
  })
  */
  yield put({ type: 'SET_SHIPPING_COUNTRY_LIST', shippingCountries });
}

function* detectUserCountryByIP() {
  // const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  var userCountry = null;

  // TODO
   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/guess_user_country`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
       "Accept": "application/json",
     }
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     userCountry = myJson.country;
   }).catch(e => {
     console.log(e);
     return e;
   });
  /*
  yield new Promise(resolve => {
    setTimeout(() => {
      userCountry = 'hk'
      resolve();
    }, MOCK_NETWORK_DELAY);
  })
  */

  yield put({ type: 'SET_DETECTED_USER_COUNTRY', country: userCountry });
  yield fetchQuote();
}
function* fetchPaymentCurrencies() {
  // const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  var paymentCurrencies = [];
  // TODO

   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_currency_options`, {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
       "Accept": "application/json",
     }
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     paymentCurrencies = myJson.currencyOptions;
   }).catch(e => {
     console.log(e);
     return e;
   });
  yield put({ type: 'SET_PAYMENT_CURRENCY_LIST', paymentCurrencies });
}
function* fetchQuote() {
  // const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  let quote = null;
  const state = yield select();
  var product = state.productList ? state.productList.find(product => product.width == (state.pages.length ? state.pages[0].width : 0) && product.height == (state.pages.length ? state.pages[0].height : 0)) : null;
  //if (!product) {
  //  product = state.productList[0];
  //}
  if (!product) {
    return;
  }
  let query = {};
  query.productId = product.id;
  query.printingMethod = product.printingMethod;
  query.width = product.width;
  query.height = product.height;
  if (state.checkout.currencyOption.name) {
    query.currency = state.checkout.currencyOption.name;
  } else return;

  query.variations = {};
  // console.log('test', product.priceOptions);
  if(product.variations.length) {
      product.variations.forEach(variation => {
          if(Object.keys(state.checkout.variations).indexOf(variation.name)!==-1) {
              if(variation.options.find(option => state.checkout.variations[variation.name].value == option.value)) {
                  query.variations[variation.name] = state.checkout.variations[variation.name].value;
              }
              else {
                  query.variations[variation.name] = variation.default;
              }
          } else {
              query.variations[variation.name] = variation.default;
          }
      });
    }
    if (state.checkout.priceOption) {
      query.copy = state.checkout.priceOption.value;
    } else {
        let filteredOptions = product.priceOptions.filter(priceOption => {
            let shouldShow = true;
            Object.keys(priceOption).forEach(key => {
                if ((key in query.variations) && priceOption[key] !== query.variations[key]) {
                    shouldShow = false;
                }
            });
            return shouldShow;
        });
        if(filteredOptions.length) {
            query.copy = filteredOptions[0].value;
        }
    }

    if (state.checkout.shippingMethod) {
    query.shippingMethod = state.checkout.shippingMethod.value;
  } else {
    query.shippingMethod = 'standard';
  }

  query.country = state.checkout.detectedUserCountry;
  let hasCommonBack = false;
  state.pages.forEach(page => {
    if (page.commonBack) {
      hasCommonBack = true;
    }
  })

  query.pageCount = (hasCommonBack && query.variations.printing_side == 'double') ? (state.pages.length - 1) : (query.variations.printing_side == 'double' ? Math.ceil(state.pages.length / 2) : state.pages.length);

  yield put({ type: 'SET_QUOTE', quote: null });

  // console.log(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_quote` + '?query=' + JSON.stringify(query));
  // TODO
   yield fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/print/get_quote` + '?query=' + JSON.stringify(query), {
     method : "POST",
     headers : {
       "Access-Control-Allow-Origin" : "*",
       "Content-Type" : "application/json;charset=UTF-8",
       "Accept": "application/json",
     },
     body: 'query='+JSON.stringify(query)
   })
   .then(function(response) {
     return response.json();
   })
   .then(function(myJson) {
     //console.log(myJson);
     quote = myJson.quote;
   }).catch(e => {
     console.log(e);
     return e;
   });


  // mock
  /*
  let description = [];
  if (product && product.displayedWidth && product.displayedHeight) {
    description.push(product.displayedWidth + ' x ' + product.displayedHeight);
  }
  if (state.checkout.variations) {
    // console.log(Object.keys(state.checkout.variations))
    Object.keys(state.checkout.variations).forEach(key => {
      description.push(state.checkout.variations[key].name);
    });
  }
  description = description.join(', ');

  let total = 0;
  let subTotal = 0;
  let shippingFee = query.shippingMethod == 'standard' ? 0 : 5;
  if (state.checkout.priceOption) {
    subTotal = parseFloat(state.checkout.priceOption.unitPrice) * parseFloat(state.checkout.priceOption.value) * query.pageCount;
  }
  total = shippingFee + subTotal;
  yield new Promise(resolve => {
    setTimeout(() => {
      quote = Object.assign({}, {
        total: total,
        shippingFee: shippingFee,
        shippingMethod: state.checkout.shippingMethod ? state.checkout.shippingMethod.name : null,
        estimatedShippingDuration: query.shippingMethod == 'standard' ? 5 : 2,
        item: {
          productId: product.id,
          productName: product.title,
          productDescription: description,
          pageCount: query.pageCount,
          copy: state.checkout.priceOption ? state.checkout.priceOption.value : 0,
          unitPrice: state.checkout.priceOption ? state.checkout.priceOption.unitPrice : 0,
          qty: state.checkout.priceOption ? (state.checkout.priceOption.value * query.pageCount) : 0,
          subTotal: total
        }
      })
      resolve();
    }, MOCK_NETWORK_DELAY);
  })
  */
  yield put({ type: 'SET_QUOTE', quote: quote });
}

function* validateAddress(action) {
  let curretLang = action.lang;
  // const cookies = new Cookies();
  // var api_token = cookies.get('api_token');
  var addressError = {};
  const state = yield select();
  var address = state.checkout.address;

  if (!address) {
    addressError.general = 'Address is empty';
    yield put({ type: 'SET_ADDRESS_ERROR', error: addressError });
    if (action.callback) action.callback();
    return;
  }

  yield put({ type: 'SET_ADDRESS_ERROR', error: null });

  if (!address.name || address.name.trim() == '') {
      addressError.name = curretLang === 'en' ? 'Full Name cannot be empty' : '請輸入全名';
  }
  if (!address.street1 || address.street1.trim() == '') {
      addressError.street1 = curretLang === 'en' ? 'Street Address cannot be empty' : '請輸入街道地址';
  }
  if ((!address.city || address.city.trim() == '') && address.city!==null) {
      addressError.city = curretLang === 'en' ?  'City cannot be empty' : '請輸入城市';
  }
  if (!address.country || address.country.trim() == '') {
      addressError.country = curretLang === 'en' ?  'Country cannot be empty' : '請輸入國家';
  }
  if (!address.postal_code || address.postal_code.trim() == '') {
      addressError.postal_code = curretLang === 'en' ?  'Postal Code cannot be empty' : '請輸入郵政區號';
  }
  if (!address.contact_no || address.contact_no.trim() == '') {
      addressError.contact_no = curretLang === 'en' ? 'Contact Number cannot be empty' : '請輸入聯絡電話';
  }
  /*
  if (!address.email || address.email.trim() == '') {
      addressError.email = 'Contact Email cannot be empty';
  } else {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(address.email).toLowerCase())){
      addressError.email = 'Contact Email format invalid';
    }
  }
  */


  /*
  // mock
  yield new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, MOCK_NETWORK_DELAY);
  })
  */
  yield put({ type: 'SET_ADDRESS_ERROR', error: Object.keys(addressError).length ? addressError : null });
  if (action.callback) action.callback(Object.keys(addressError).length ? addressError : null);
}

function sendVerificationEmail(action){
  let callback = action.callback;
  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  //alert(api_token);


  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/resendVerificationEmail`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json",
    },
    body: JSON.stringify({
     "user_token" : api_token
    }),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
     //shippingCountries = myJson.shippingCountries;
      if (process.env.NODE_ENV !== 'production') {
          console.log(myJson);
      }
     callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function addFolder(data){

  const cookies = new Cookies();
  var api_token = cookies.get('api_token');
  var code = cookies.get('code');
  var param = {
    "folder_id" : 0,
    "folder_name" : data.folder_name,
    "code" : code,
    "api_token" : api_token,
  };
  fetch(`${process.env.REACT_APP_API_HOST}/ajax/design/create-folder`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json",
    },
    body: JSON.stringify(param),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
      if (process.env.NODE_ENV !== 'production') {
          console.log(myJson);
      }
     data.callback(myJson);
  }).catch(e => {
    console.log(e);
    return e;
  });

}

function duplicateProject(data) {
  let code = data.code;
  let changes = data.changes;
  let variations = data.variations;
  // let callback = data.callback;
  let param = {
    "changes" : JSON.stringify(changes),
    "variations" : JSON.stringify(variations),
  };
  fetch(`${process.env.REACT_APP_MARKETPLACE_API_HOST}/ajax/design/duplicate/${code}`, {
    method : "POST",
    headers : {
      "Access-Control-Allow-Origin" : "*",
      "Content-Type" : "application/json;charset=UTF-8",
      "Accept": "application/json",
    },
    body: JSON.stringify(param),
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
     //console.log(myJson);
     if (data.callback) {
      data.callback(myJson);
     }
  }).catch(e => {
    console.log(e);
    return e;
  });
}

function* watchProjectSaveAsync() {
  yield takeEvery('SAVE_PROJECT', saveProject);
  yield takeEvery('CREATE_PROJECT', createProject);
  yield takeEvery('LOAD_PROJECT', loadProject);

  yield takeEvery('CHECK_USER_VERIFY_STATUS', checkUserVerifyStatus);
  yield takeEvery('CHECK_USER_SESSION', checkUserSession);
  yield takeEvery('AUTH', auth);
  yield takeEvery('LOAD_PRINT_ONLY', loadPrintOnly);
  yield takeEvery('EXPORT_PROJECT', exportProject);

  // @deprecated mine gallery
  yield takeEvery('UPLOAD_MINE', uploadMine);
  //yield takeEvery('DELETE_UPLOADED_IMG', deleteUploadedImg);
  yield takeEvery('GET_DESIGN_ELEMENT', getDesignElement);

  yield takeEvery('GET_RECENTLY_USED_IMAGES', getRecentlyUsedImages);
  yield takeEvery('SAVE_ELEMENT_ID', saveElementId);
  yield takeEvery('GET_DESIGN_CATEGORY', getDesignCategory);
  yield takeEvery('SAVE_DESIGNER_TEMPLATE', saveDesignerTemplate);
  yield takeEvery('BIND_PROJECT', bindProject);
  yield takeEvery('SAVE_PREVIEW', savePreview);
  yield takeEvery('PROMPT_LOGIN', promptLogin);
  yield takeEvery('SET_PROJECT_SHARE', setProjectShare);
  yield takeEvery('SET_PROJECT_SHARE_TEAM', setProjectShareTeam);
  yield takeEvery('GET_PROJECT_SHARE', getProjectShare);
  yield takeEvery('PROMPT_SLACK_LOGIN' , promptSlackLogin);
  yield takeEvery('GET_FOLDER_LIST' , getFolderList);
  yield takeEvery('SET_PROJECT_FOLDER' , setProjectFolder);
  yield takeEvery('CHECK_SOCIAL_MEDIA_TOKEN', checkSocialMediaToken);
  yield takeEvery('SLACK_UPLOAD_FILE', slackUploadFile);
  yield takeEvery('GET_CUSTOM_FONTS', getCustomFonts);
  yield takeEvery('LOAD_PRODUCT_LIST', fetchProductList);
  yield takeEvery('LOAD_SHIPPING_COUNTRY_LIST', fetchShippingCountries);
  yield takeEvery('LOAD_PAYMENT_CURRENCY_LIST', fetchPaymentCurrencies);
  yield takeEvery('DETECT_USER_COUNTRY_BY_IP', detectUserCountryByIP);
  yield takeLatest('FETCH_QUOTE', fetchQuote);
  yield takeEvery('CREATE_ORDER', createOrder);
  yield takeEvery('VALIDATE_ADDRESS', validateAddress);
  yield takeEvery('SET_MY_FAVOURITE', setMyFavourite);
  yield takeEvery('SEND_VERIFICATION_EMAIL', sendVerificationEmail);
  yield takeEvery('ADD_FOLDER', addFolder);
  yield takeEvery('DUPLICATE_PROJECT', duplicateProject);
}


// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchProjectSaveAsync()
  ])
}
