//import { combineReducers } from 'redux';

const app = (state = {
  currentTab: null,
}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_TAB':
      if (state.currentTab === action.tab) {
        return {...state, currentTab: null}
      } else {
        return {...state, currentTab: action.tab}
      }
    default:
      return state
  }

}

export default app
