import React from 'react';
import classNames from "classnames";
//import { connect } from 'react-redux';
//import Victor from "victor";
//import $ from 'jquery';

import Loading from "../Loading";

//css and images
import './IconRightButton.scss';

class IconButton extends React.PureComponent {

    onClick(e) {
      if (!(this.props.isLoading || this.props.isDisabled)){
        this.props.onClick(e);
      }
    }

    render() {
        const publish_btn_style = this.props.publish_class ? 'publish_icon_button' : '';
        const active_box = this.props.share_box_active ? 'btn_active' : '';
        const is_primary = this.props.is_primary ? 'btn_artfia' : '';
        const is_disabled = this.props.isDisabled ? 'btn_disabled' : '';

        const disabled_text = this.props.disabled_text && is_disabled ? this.props.disabled_text : this.props.text;

        let iconStyle = {};
        if (this.props.iconSize)  {
          iconStyle.width = this.props.iconSize;
        }
      return (
          <button className={classNames("social_icon_button", "btn_outline", "share_header_content", publish_btn_style,active_box, is_primary,
                            {inactive: this.props.isLoading || this.props.inactive}, this.props.iconType,
                            {img_only: !this.props.text}, is_disabled)}
                    onClick={this.onClick.bind(this)}>
            <span className="social_text_container">
              <div className="text_box">

                <span className={classNames("icon", {hide: this.props.isLoading || !this.props.src})}>
                  <img className={classNames("action_btn")} src={this.props.src} style={iconStyle}/>
                </span>
                <span className={classNames("icon", {hide: !this.props.isLoading || !this.props.src})}>
                  <Loading color={this.props.textColor} size={20}></Loading>
                </span>
                <span className={classNames({hide: !this.props.text},'btn_text text_nowrap')}>{disabled_text}</span>

              </div>
            </span>
          </button>
        );
    }
}

export default IconButton;
