import React from 'react';
//import $ from 'jquery';
//import classNames from "classnames";
import { connect } from 'react-redux';

//css and images
//import ImageComponent from '../Image';
import Button from '../Button';

import { toggleItemVisibility, setElementProperty } from '../../../actions';

class ConstraintPanel extends React.PureComponent {
    _isMounted = false;
    constructor(props, context) {
        super(props, context);
        this.state = {
            singleLine: !!props.data.singleLine,
            maxChar: props.data.maxChar ? props.data.maxChar : 15,
            replaceable: !!props.data.replaceable,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(this._isMounted)
        {
            this.setState({
                singleLine: !!props.data.singleLine,
                maxChar: props.data.maxChar ? props.data.maxChar : 15,
                replaceable: !!props.data.replaceable,
            })
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }

    handleMaxCharChange(event) {
        this.setState({maxChar: parseInt(event.currentTarget.value)});
        this.props.setElementProperty(this.props.data.hash, {maxChar: parseInt(event.currentTarget.value)})
    }

    handleSingleLineChange() {
        this.props.setElementProperty(this.props.data.hash, {singleLine: !this.state.singleLine});
        this.setState({singleLine: !this.state.singleLine});
    }

    handleReplacableChange() {
        this.props.setElementProperty(this.props.data.hash, {replaceable: !this.state.replaceable});
        this.setState({replaceable: !this.state.replaceable});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(!this.state) {
            return '';
        }
        return (
            <div className="slider_control_container">
                <Button handleClick={this.props.toggleItemVisibility.bind(this, this.props.name)} id="Constraint" key="Constraint" text="Constraint"/>
                {(this.props.itemsVisibility[this.props.name]) ? (<div className="slider_container">
                    <div key="ToolBar Second Layer" className="square_contianer s_contianer_align">
                        {this.props.data.type==='text'?
                        <React.Fragment>
                            <label className="label_range_container">
                                <div className="spacing_label">Single Line</div>
                                <input type="checkbox" checked={this.state.singleLine} onChange={(e) => {this.handleSingleLineChange(e)}}/>
                            </label>
                            <label className="label_range_container">
                                <div className="spacing_label">Max Char</div>
                                <input type="number" name="line_height" id="line_height" value={this.state.maxChar} onChange={(e) => {this.handleMaxCharChange(e)}} className="spacing_text_val"/>
                            </label>
                        </React.Fragment>:
                            <React.Fragment>
                                <label className="label_range_container">
                                    <div className="spacing_label">Replaceable</div>
                                    <input type="checkbox" checked={this.state.replaceable} onChange={(e) => {this.handleReplacableChange(e)}}/>
                                </label>
                            </React.Fragment>
                        }
                    </div>
                </div>) : null}
            </div>
        )
    }


}

const mapStateToProps = state => ({
    itemsVisibility: state.itemsVisibility
});

const mapDispatchToProps = dispatch => ({
    setElementProperty: (hash, change) => dispatch(setElementProperty(hash, change)),
    toggleItemVisibility: (item) => dispatch(toggleItemVisibility(item))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    )(ConstraintPanel);
