import React, { Suspense, lazy } from 'react';
import classNames from "classnames";
// import ReactDOM from 'react-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import $ from 'jquery';
import * as Sentry from '@sentry/react';

import ProjectControlPanel from '../ProjectControlPanel';
import DesignPanel from '../DesignPanel';
//import HomePanel from '../Home';

//css and images
import './project.css';

import { /*clearActiveElements, createProject, */loadCanvasData, alignSaveId, loadProductList, currentLang, setProjectTarget } from '../../actions';
// import { loadPaymentCurrencyList } from '../../actions/checkoutActions';

const HomePanel = lazy(() => import('../Home'));
//const ProjectControlPanel = lazy(() => import('../ProjectControlPanel'));

class Project extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            error: null,
            print_only : (window.location.href.indexOf("print-only") !== -1),
        };
        //this.designPanelRef = React.createRef();
        var lang='en';

        if (window.location.search) {
           var params = queryString.parse(window.location.search);
            if (params) {
                lang = params.lang;
            }
        }
        this.props.currentLang(lang);
        if (!this.state.print_only) {
            //this.props.loadBraintreeClientToken();
            this.props.loadProductList(lang);
        }
        this.getZoomContainerSize = this.getZoomContainerSize.bind(this);
    }

    componentDidCatch(error, errorInfo) {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }


    getZoomContainerSize() {
        if ($(".convas_container").length) {
            return {
                width: $(".convas_container").width() - 100,
                height: $(".convas_container").height() * 0.9 - 50,
            }
        } else {
            return {
                width: $(".project_container").width(),
                height: $(".project_container").height(),
            }
        }
    }

    render() {
        const projet_control_panel = <ProjectControlPanel key="projet_control_panel" getZoomContainerSize={this.getZoomContainerSize}/>;
        const design_panel = <DesignPanel ref="designPanelRef" key="design_panel" print_only={false} no_side={false}
                                getZoomContainerSize={this.getZoomContainerSize}/>;

        const projet_control_panel2 = <ProjectControlPanel key="projet_control_panel" getZoomContainerSize={this.getZoomContainerSize}
                                        is_mobile={true} />;
        const design_panel2 = <DesignPanel ref="designPanelRef" key="design_panel" print_only={false} no_side={true} is_mobile={true}
                                getZoomContainerSize={this.getZoomContainerSize}/>;

        const page_only = <DesignPanel ref="designPanelRef" key="design_panel" print_only={true} getZoomContainerSize={this.getZoomContainerSize}/>;

        const home_panel = <HomePanel ref="homePanelRef" key="home_panel" />;

        const normal = () => [projet_control_panel,design_panel];
        const print_only = () => [page_only];

        const mobile = () => [projet_control_panel2,design_panel2];

        const home = () => [home_panel];

        const projet_control_panel_slack = <ProjectControlPanel key="projet_control_panel" getZoomContainerSize={this.getZoomContainerSize} slack_code={true}/>;

        const slack = () => [projet_control_panel_slack, design_panel];

        return (
            <div key="Project" className="project_container">
                <div className="second_layer">
                    <Suspense fallback={<span />}>
                    <Router>
                        <main className="main">
                            <Switch>
                            {
                                (() => {
                                    let routers = [
                                        <Route exact path="/" key="/" component={normal} />,
                                        <Route exact path="/design-builder/:code"  key="/design-builder/:code" component={normal} />,
                                        <Route exact path="/design-builder/:code/:user" key="/design-builder/:code/:user" component={normal} />
                                    ];
                                    if (`${process.env.REACT_APP_STAGE}` !== 'production' || document.referrer == `${process.env.REACT_APP_API_HOST}/`) {
                                        routers.push(<Route exact path="/design-builder/:code/:user/print-only" key="/design-builder/:code/:user/print-only" component={print_only} />);
                                    }
                                    routers.push.apply(routers, [
                                        <Route exact path="/design-builder/:code/:user/mobile" key="/design-builder/:code/:user/mobile" component={mobile} />,
                                        <Route exact path="/design-builder/:code/:user?lang=:lan" key="/design-builder/:code/:user?lang=:lan" component={normal} />,
                                        <Route exact path="/slack_page" key="/slack_page" component={slack} />,
                                        <Route exact path="/home" key="/home" component={home} />
                                    ]);
                                    return routers;
                                })()
                            }
                            </Switch>
                        </main>
                    </Router>
                    </Suspense>
                </div>
                <div className={classNames('loading_overlay', {'hide' : !this.props.isLoading}) }/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoading: state.isProjectLoading,
    project_target: state.project_target,
    current_lang: state.current_lang
});

const mapDispatchToProps = dispatch => ({
  //createProject: (data) => dispatch(createProject(data)),
  loadCanvasData: (data) => dispatch(loadCanvasData(data)),
  alignSaveId: () => dispatch(alignSaveId()),
  loadProductList: (data) => dispatch(loadProductList(data)),
  setProjectTarget: (project_target) => dispatch(setProjectTarget(project_target)),
  currentLang: (lang) => dispatch(currentLang(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
