// addthis.js

let injected = false;

export function addAddThisScript() {
    if (injected) {
        return Promise.resolve(true);
    }

    return new Promise((resolve, reject)=> {
        injected = true;
        if (document.getElementById('addthis')) {
            return resolve(true);
        }
        const pjs = document.getElementsByTagName('script')[0];
        const js = document.createElement('script');
        js.id = 'addthis';
        js.onload = function() {
            // Your code here
            resolve(true);
        };
        js.oerror = function(e) {
            injected = false;
            reject(e);
        };
        js.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c788b3cf5aa1c86";
        pjs.parentNode.insertBefore(js, pjs);
    });
}
