export default
[
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_1.png",
        title: 'Social Media',
        width: 400,
        height:384
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_2.png",
        title: 'Presentation',
        width: 400,
        height:566
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_3.png",
        title: 'Facebook Cover',
        width: 321,
        height:189
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_4.png",
        title: 'Flyer',
        width: 315,
        height:185
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_5.png",
        title: 'Facebook Post',
        width: 400,
        height:400
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_6.png",
        title: 'Instragram Post',
        width: 400,
        height:400
    },
    {
        photoUrl: "http://test.joindesign.com/images/saved_design/img_7.png",
        title: 'Poster',
        width: 400,
        height:400
    }
]
