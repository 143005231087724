import React from 'react';
import classNames from "classnames";
//import { connect } from 'react-redux';
//import Victor from "victor";
//import $ from 'jquery';

import Loading from "../Loading";

//css and images
import './iconButton.scss';

class IconButton extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
      if (!this.props.isLoading) {
        this.props.onClick(e);
      }
    }

    render() {
      return (
        <button className={classNames("icon_button", "btn_outline", "share_header_content", {inactive: this.props.isLoading || this.props.inactive}, this.props.iconType, {img_only: !this.props.text})} onClick={this.onClick}>
            <span className="text_container"><div className="text_box">
                 <span className={classNames("icon", {hide: this.props.isLoading || !this.props.src})}>
                 <img className={classNames("action_btn")} src={this.props.src}/>
                 </span>
                 <span className={classNames("icon", {hide: !this.props.isLoading || !this.props.src})}>
                 <Loading color={this.props.textColor} size={20}></Loading>
                 </span>
                 <span className={classNames({hide: !this.props.text}, "text_width")}>{this.props.text}</span>
            </div></span>
         </button>
        );
    }
}

export default IconButton;
