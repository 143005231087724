import React from 'react';
import classNames from "classnames";
//css
import './pickerPanel.css';
import check_icon from './check-mark.png';
import './spinnerdots.svg';
//import LazyLoad from 'react-lazyload';

export default class PickerPanel extends React.PureComponent {

    render() {
        const font = this.props.data.value;
        const style = this.props.currentOption == this.props.data.value ? "show_check_icon" : "";
        const img_icon = [this.props.data.img];
        const disable_item = this.props.data.value === "fonts_label" ? "disable_list_item" : "";
        return (

            <li className="list_style"
                onClick={this.props.data.value === "fonts_label" ? "" : (e) => this.props.handleChange(e, this.props.data)}>
                <button className="list_btn">
                    <div className="list_box picker_panel_list_box_width">
                        <div className="label_container">
                            {this.props.data.img ? <img className="picker_option" src={img_icon} width={350}/> :
                                < span className={classNames("list_title font_heading", disable_item)}
                                       style={{fontFamily: font}}>{this.props.data.label}</span>
                            }
                        </div>
                        <span><img src={check_icon} className={classNames("check_icon", style)}/></span>
                    </div>
                </button>
            </li>

        )
    }
}
