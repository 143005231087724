import React from 'react';
import Modal from 'react-modal';
//import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
//import classNames from "classnames";

//css and images
import './modal.scss';
import banner from '../Home/JD Homepage-09_cc-09.svg';
import '../ProgressBar/JD-download.gif';
// eslint-disable-next-line
import ProgressBar from '../ProgressBar';

class ModalComponent extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            modalIsOpen: false,
            percentage: 0,
            msg : [
                (this.props.current_lang === 'en'?"Life is about making an impact, not making an income. --Kevin Kruse":"只要你能夢想到的，你就能做到。　－　華特‧迪士尼"),
                (this.props.current_lang === 'en'?"Whatever the mind of man can conceive and believe, it can achieve. –Napoleon Hill":"限制只存在於我們的思想中。但如果我們運用自己的想象力，就會創造無限的可能性。　－　傑米"),
                (this.props.current_lang === 'en'?"Strive not to be a success, but rather to be of value. –Albert Einstein":"一個人能想到和相信的事，就能做到。　－　拿破崙‧希爾"),
                (this.props.current_lang === 'en'?"Two roads diverged in a wood, and I—I took the one less traveled by, And that has made all the difference.  –Robert Frost":"他們能做到因為他們認為他們能做到。　－　維吉爾"),
                (this.props.current_lang === 'en'?"I attribute my success to this: I never gave or took any excuse. –Florence Nightingale":"事情總是發生在當你真的相信它的時候；而相信一件事情就能讓它發生。　－　法蘭克·洛伊·萊特"),
                (this.props.current_lang === 'en'?"You miss 100% of the shots you don’t take. –Wayne Gretzky":"要完全相信，我們首先得懷疑。　－　斯坦尼斯洛斯"),
                (this.props.current_lang === 'en'?"I've missed more than 9000 shots in my career. I've lost almost 300 games. 26 times I've been trusted to take the game winning shot and missed. I've failed over and over and over again in my life. And that is why I succeed. –Michael Jordan":"我思故我在。　－　笛卡兒"),
                (this.props.current_lang === 'en'?"The most difficult thing is the decision to act, the rest is merely tenacity. –Amelia Earhart":""),
                (this.props.current_lang === 'en'?"Every strike brings me closer to the next home run. –Babe Ruth":"成為你想在世界上看到的改變。　－　甘地"),
                (this.props.current_lang === 'en'?"Definiteness of purpose is the starting point of all achievement. –W. Clement Stone":"若非我等，更待何人？若非此刻，更待何時？　－　羅伯特‧甘迺迪"),
                (this.props.current_lang === 'en'?"Life isn't about getting and having, it's about giving and being. –Kevin Kruse":"相信自己能做到，你就已經成功了一半。　－　西奧多‧羅斯福"),
                (this.props.current_lang === 'en'?"Life is what happens to you while you’re busy making other plans. –John Lennon":"只有去嘗試做一些超出你現有能力的事情，你才能有所進步。"),
                (this.props.current_lang === 'en'?"We become what we think about. –Earl Nightingale":"你一生中最大的錯誤就是一直擔心會犯錯。　－　阿爾伯特‧哈伯德"),
                (this.props.current_lang === 'en'?"Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do, so throw off the bowlines, sail away from safe harbor, catch the trade winds in your sails.  Explore, Dream, Discover. –Mark Twain":"每一個失敗也會帶來等價的成功。　－　拿破崙‧希爾"),
                (this.props.current_lang === 'en'?"Life is 10% what happens to me and 90% of how I react to it. –Charles Swindoll":"我不是失敗，我只是找到了一萬個行不通的方法。　－　愛迪生"),
                (this.props.current_lang === 'en'?"The most common way people give up their power is by thinking they don’t have any. –Alice Walker":"一個從不犯錯誤的人，一定從來沒有嘗試過任何新的事物。　－　愛因斯坦"),
                (this.props.current_lang === 'en'?"The mind is everything. What you think you become.  –Buddha":"成功就是能夠懷着熱情從一次失敗走到另一次失敗。　－　溫斯頓·邱吉爾"),
                (this.props.current_lang === 'en'?"The best time to plant a tree was 20 years ago. The second best time is now. –Chinese Proverb":"為了獲得成功，你對成功的慾望要超過你對失敗的恐懼。　－　比爾·寇司比"),
                (this.props.current_lang === 'en'?"An unexamined life is not worth living. –Socrates":"只有敢於經受重大挫折的人，才能獲得偉大的成就。　－　羅伯特·弗朗西斯·甘迺迪"),
                (this.props.current_lang === 'en'?"Eighty percent of success is showing up. –Woody Allen":"只有那些冒險走太遠的人可能發現一個人能走多遠。　－　艾略特"),
                (this.props.current_lang === 'en'?"Your time is limited, so don’t waste it living someone else’s life. –Steve Jobs":"每一位藝術家開始是業餘的愛好者。　－　愛默生"),
                (this.props.current_lang === 'en'?"Winning isn’t everything, but wanting to win is. –Vince Lombardi":"你不必開始時就很棒，但你必須開始才能成為最棒的。　－　吉格‧金克拉"),
                (this.props.current_lang === 'en'?"I am not a product of my circumstances. I am a product of my decisions. –Stephen Covey":"即使是最小的事情，也要傾注你的心、你的思想和你的靈魂。這就是成功的秘密。　－　斯瓦米·希瓦難陀"),
                (this.props.current_lang === 'en'?"Every child is an artist.  The problem is how to remain an artist once he grows up. –Pablo Picasso":"偉大的人從不抱怨缺乏機會。　－　愛默生"),
                (this.props.current_lang === 'en'?"You can never cross the ocean until you have the courage to lose sight of the shore. –Christopher Columbus":"機會與困難，往往是一線之間。　－　愛因斯坦"),
                (this.props.current_lang === 'en'?"I’ve learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel. –Maya Angelou":"天才和靈感，說的其實是同一回事。　－　維克多‧雨果"),
                (this.props.current_lang === 'en'?"Either you run the day, or the day runs you. –Jim Rohn":"天才是百分之九十九的努力外加百分之ㄧ的靈感。　－　愛迪生"),
                (this.props.current_lang === 'en'?"Whether you think you can or you think you can’t, you’re right. –Henry Ford":"不要為成功而努力，要為做一個有價值的人而努力。　－　愛因斯坦"),
                (this.props.current_lang === 'en'?"The two most important days in your life are the day you are born and the day you find out why. –Mark Twain":"最後，有意義有價值的，不是你活了多少年，而是你在有生之年中怎麼生活。　－　愛亞伯拉罕‧林肯"),
                (this.props.current_lang === 'en'?"Whatever you can do, or dream you can, begin it.  Boldness has genius, power and magic in it. –Johann Wolfgang von Goethe":"如何判斷一天過得好不好，不要看你收穫了多少，而要看你種下去了多少種子。　－　羅伯特·路易斯·史蒂文森"),
                (this.props.current_lang === 'en'?"The best revenge is massive success. –Frank Sinatra":"成功不能令你快樂，快樂卻能令你成功，如果你愛你在做的事，你必定能成功。　－　艾伯特·史懷哲"),
                (this.props.current_lang === 'en'?"People often say that motivation doesn’t last. Well, neither does bathing.  That’s why we recommend it daily. –Zig Ziglar":"欲得到自己想要的，必須先幫助別人得到他想要的。　－　吉格‧金克拉"),
                (this.props.current_lang === 'en'?"Life shrinks or expands in proportion to one's courage. –Anais Nin":"你因達到目標所得到的沒有你因達到目標所成為的人那麼重要。　－　吉格‧金克拉"),
                (this.props.current_lang === 'en'?"If you hear a voice within you say “you cannot paint,” then by all means paint and that voice will be silenced. –Vincent Van Gogh":"我無法告訴你成功之道，但我能告訴你失敗之因，那就是試圖討好所有人。　－　赫伯特‧斯沃普"),
                (this.props.current_lang === 'en'?"There is only one way to avoid criticism: do nothing, say nothing, and be nothing. –Aristotle":"你的提問能決定你的生活質量。　－　自我成長先生"),
                (this.props.current_lang === 'en'?"Ask and it will be given to you; search, and you will find; knock and the door will be opened for you. –Jesus":"你看見東西就問為什麼，而我夢想那些還沒出現的，並且問為什麼不呢？　－　蕭伯納"),
                (this.props.current_lang === 'en'?"The only person you are destined to become is the person you decide to be. –Ralph Waldo Emerson":"卓越不是一個終點，它是一個持續且永遠不會結束的旅程。　－　博恩·崔西"),
                (this.props.current_lang === 'en'?"Go confidently in the direction of your dreams.  Live the life you have imagined. –Henry David Thoreau":"一旦不能集中註意力在目標上，就會遇到各種障礙。　－　亨利·福特"),
                (this.props.current_lang === 'en'?"When I stand before God at the end of my life, I would hope that I would not have a single bit of talent left and could say, I used everything you gave me. –Erma Bombeck":"人生中最大的樂趣來自於，做一些別人認為你做不到的事。　－　沃爾特·白芝霍特"),
                (this.props.current_lang === 'en'?"Few things can help an individual more than to place responsibility on him, and to let him know that you trust him.  –Booker T. Washington":"追逐月亮，即使你追不上，你還可以落在星辰之中。　－　萊斯‧布朗"),
                (this.props.current_lang === 'en'?"Certain things catch your eye, but pursue only those that capture the heart. – Ancient Indian Proverb":"如果我看得比別人遠，是因為我站在巨人的肩膀上。　－　牛頓"),
                (this.props.current_lang === 'en'?"Believe you can and you’re halfway there. –Theodore Roosevelt":"鳥兒希望成為一朵白雲，白雲卻希望成為一隻飛鳥。　－　泰戈爾"),
                (this.props.current_lang === 'en'?"Everything you’ve ever wanted is on the other side of fear. –George Addair":"鳥兒靠自己的翅膀飛，永遠都不會飛的太高。　－　威廉·布萊克"),
                (this.props.current_lang === 'en'?"We can easily forgive a child who is afraid of the dark; the real tragedy of life is when men are afraid of the light. –Plato":"為避免別人批評而什麼也不做，什麼也不說的人最終什麼也不是。　－　艾爾伯特·哈伯德"),
                (this.props.current_lang === 'en'?"Teach thy tongue to say, \"I do not know,\" and thous shalt progress. –Maimonides":"別人對你的看法並不一定成為你的現實。　－　萊斯布朗"),
                (this.props.current_lang === 'en'?"Start where you are. Use what you have.  Do what you can. –Arthur Ashe":"遠離那些試著小看你的企圖心的人。　－　馬克·吐溫"),
                (this.props.current_lang === 'en'?"When I was 5 years old, my mother always told me that happiness was the key to life.  When I went to school, they asked me what I wanted to be when I grew up.  I wrote down ‘happy’.  They told me I didn’t understand the assignment, and I told them they didn’t understand life. –John Lennon":"我寧願做我自己而被人厭惡，也不願為了迎合別人而偽裝自己。　－　科特·柯本"),
                (this.props.current_lang === 'en'?"Fall seven times and stand up eight. –Japanese Proverb":"我們會不會在不斷地假裝偽裝中，終有一天忘記自己"),
                (this.props.current_lang === 'en'?"When one door of happiness closes, another opens, but often we look so long at the closed door that we do not see the one that has been opened for us. –Helen Keller":"你生於原創，別死於模仿。　－　約翰·梅森"),
                (this.props.current_lang === 'en'?"Everything has beauty, but not everyone can see. –Confucius":"永遠做一流版本的自己，不做二流版本的別人　－　朱迪·加蘭"),
                (this.props.current_lang === 'en'?"How wonderful it is that nobody need wait a single moment before starting to improve the world. –Anne Frank":"在某人面前，你或許會覺得自己一文不值，但在別人眼裡，你或許就是無價之寶。所以任何時候都不要貶低自己。"),
                (this.props.current_lang === 'en'?"When I let go of what I am, I become what I might be. –Lao Tzu":"我不能相信上帝把放在這個地球上的我們是如此普通的。　－　盧·霍茲"),
                (this.props.current_lang === 'en'?"Life is not measured by the number of breaths we take, but by the moments that take our breath away. –Maya Angelou":"我要緊緊扼住命運的咽喉，他不能使我完全屈服。　－　貝多芬"),
                (this.props.current_lang === 'en'?"Happiness is not something readymade.  It comes from your own actions. –Dalai Lama":"夢想你的夢想；到你想到的地方；做你想做的自己吧！因為你只能活一次。"),
                (this.props.current_lang === 'en'?"If you're offered a seat on a rocket ship, don't ask what seat! Just get on. –Sheryl Sandberg":"不要讓生命浪費於懷疑與恐懼中。　－　愛默生"),
                (this.props.current_lang === 'en'?"First, have a definite, clear practical ideal; a goal, an objective. Second, have the necessary means to achieve your ends; wisdom, money, materials, and methods. Third, adjust all your means to that end. –Aristotle":"如果你拒絕懼怕，沒有什麼東西可以嚇到你。　－　甘地"),
                (this.props.current_lang === 'en'?"If the wind will not serve, take to the oars. –Latin Proverb":"別讓你做不到的事，影響到你做得到的事。　－　約翰‧伍登"),
                (this.props.current_lang === 'en'?"You can’t fall if you don’t climb.  But there’s no joy in living your whole life on the ground. –Unknown":"及時行樂，終身學習。　－　甘地"),
                (this.props.current_lang === 'en'?"We must believe that we are gifted for something, and that this thing, at whatever cost, must be attained. –Marie Curie":"生活要永遠充滿夢想，把每天當成生命的最後一天。　－　占士·甸"),
                (this.props.current_lang === 'en'?"Too many of us are not living our dreams because we are living our fears. –Les Brown":"混混噩噩的生活不值得過。　－　蘇格拉底"),
                (this.props.current_lang === 'en'?"Challenges are what make life interesting and overcoming them is what makes life meaningful. –Joshua J. Marine":"人家活著是要吃東西，我是為了活而吃。　－　蘇格拉底"),
                (this.props.current_lang === 'en'?"If you want to lift yourself up, lift up someone else. –Booker T. Washington":"生存還是死亡，這是個問題。　威廉·莎士比亞"),
                (this.props.current_lang === 'en'?"I have been impressed with the urgency of doing. Knowing is not enough; we must apply. Being willing is not enough; we must do. –Leonardo da Vinci":"當你出生時，你哭著，身邊的人笑著。當你離世時，你笑著，身邊的人哭著。"),
                (this.props.current_lang === 'en'?"Limitations live only in our minds.  But if we use our imaginations, our possibilities become limitless. –Jamie Paolinetti":"愛是人生的合弦，而不是孤單的獨奏曲 。　－　貝多芬"),
                (this.props.current_lang === 'en'?"You take your life in your own hands, and what happens? A terrible thing, no one to blame. –Erica Jong":"音樂是調味濟，會讓愛情新鮮長久。　－　威廉·莎士比亞"),
                (this.props.current_lang === 'en'?"What’s money? A man is a success if he gets up in the morning and goes to bed at night and in between does what he wants to do. –Bob Dylan":"萬物皆有動人之處，然非眾人皆能洞悉。　－　孔子"),
                (this.props.current_lang === 'en'?"I didn’t fail the test. I just found 100 ways to do it wrong. –Benjamin Franklin":"名字有什麽關系？把玫瑰花叫做別的名稱，它還是照樣芳香。　－　威廉·莎士比亞"),
                (this.props.current_lang === 'en'?"In order to succeed, your desire for success should be greater than your fear of failure. –Bill Cosby":"傳遞光明的方式有兩種，蠟燭或鏡子。　－　伊迪絲·沃頓"),
                (this.props.current_lang === 'en'?"A person who never made a mistake never tried anything new. – Albert Einstein":"越是在黑暗的時候，我們越要集中精神尋找光明。　－　亞里士多德‧奧納西斯"),
                (this.props.current_lang === 'en'?"The person who says it cannot be done should not interrupt the person who is doing it. –Chinese Proverb":"給予光亮，人們就能找到前方的路。　－　艾拉·貝克"),
                (this.props.current_lang === 'en'?"There are no traffic jams along the extra mile. –Roger Staubach":"找到出口的最好方式就是一路走到底。 —　羅伯特·弗羅斯特"),
                (this.props.current_lang === 'en'?"It is never too late to be what you might have been. –George Eliot":"我們不是找到出路，就是走出一條路來。　－　漢尼拔"),
                (this.props.current_lang === 'en'?"You become what you believe. –Oprah Winfrey":"教育的根是苦的，但其果實是甜的。　－　亞里斯多德"),
                (this.props.current_lang === 'en'?"I would rather die of passion than of boredom. –Vincent van Gogh":"教育是一個逐步發現自己無知的過程。　－　杜蘭特"),
                (this.props.current_lang === 'en'?"A truly rich man is one whose children run into his arms when his hands are empty. –Unknown":"想像力比知識更重要。　－　愛因斯坦"),
                (this.props.current_lang === 'en'?"It is not what you do for your children, but what you have taught them to do for themselves, that will make them successful human beings.  –Ann Landers":"讀書使人知識淵博，言談使人處世機敏，寫作使人判斷精準。　－　培根"),
                (this.props.current_lang === 'en'?"If you want your children to turn out well, spend twice as much time with them, and half as much money. –Abigail Van Buren":"正像空容器發聲最大，智力最低者最善於嘮叨不休。　－　威廉·莎士比亞"),
                (this.props.current_lang === 'en'?"Build your own dreams, or someone else will hire you to build theirs. –Farrah Gray":"你不會因為憤怒而受到懲罰，但會被自己的憤怒懲罰。　－　佛"),
                (this.props.current_lang === 'en'?"The battles that count aren't the ones for gold medals. The struggles within yourself--the invisible battles inside all of us--that's where it's at. –Jesse Owens":"原諒你的敵人；沒什麼能比這樣更讓他們生氣。　－　奧斯卡·王爾德"),
                (this.props.current_lang === 'en'?"Education costs money.  But then so does ignorance. –Sir Claus Moser":"當你的敵人犯錯的時候，千萬不要去打擾他。 －　拿破倫"),
                (this.props.current_lang === 'en'?"I have learned over the years that when one's mind is made up, this diminishes fear. –Rosa Parks":"幸福不應該等到未來去擁有，而是為了當下所準備。　—　吉姆·羅恩"),
                (this.props.current_lang === 'en'?"It does not matter how slowly you go as long as you do not stop. –Confucius":"最幸福的人並不是擁有最好的一切，只不過他們可以把一切都變成最好。"),
                (this.props.current_lang === 'en'?"If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough. –Oprah Winfrey":"記住最幸福的人不是得到最多，而是付出最多。　－　傑克遜‧布朗"),
                (this.props.current_lang === 'en'?"Remember that not getting what you want is sometimes a wonderful stroke of luck. –Dalai Lama":"記住，如果你在任何時候需要一隻手來幫助你，你可以在自己每條手臂的末端找到它。隨著你的成長，你會發現你有兩隻手，一隻用來幫助自己，另一隻用來幫助別人。　－　奧黛麗‧赫本"),
                (this.props.current_lang === 'en'?"You can’t use up creativity.  The more you use, the more you have. –Maya Angelou":"據我所知，人們會忘記你所說的，人們會忘記你所做的，但人們永遠不會忘記你帶給他們的感覺。　－　馬婭·安傑盧"),
                (this.props.current_lang === 'en'?"Dream big and dare to fail. –Norman Vaughan":"己所不欲，勿施於人。　－　孔子"),
                (this.props.current_lang === 'en'?"Our lives begin to end the day we become silent about things that matter. –Martin Luther King Jr.":"你希望別人如何對待你，你就如何對待別人。　－　伊索"),
                (this.props.current_lang === 'en'?"Do what you can, where you are, with what you have. –Teddy Roosevelt":"無論善行多麼微不足道，都不會被視而不見。　－　伊索"),
                (this.props.current_lang === 'en'?"If you do what you’ve always done, you’ll get what you’ve always gotten. –Tony Robbins":"善良是聾子能聽，瞎子能看的語言。　－　馬克·吐溫"),
                (this.props.current_lang === 'en'?"Dreaming, after all, is a form of planning. –Gloria Steinem":"真正的財富是健康，而不是金銀財寶。　－　甘地"),
                (this.props.current_lang === 'en'?"It's your place in the world; it's your life. Go on and do all you can with it, and make it the life you want to live. –Mae Jemison":"當一道快樂之門關上了，另一道門會隨之打開。但是，我們常常眷戀著那道關上了的門，而看不見另一道門已經打開了。 海倫·凱勒"),
                (this.props.current_lang === 'en'?"You may be disappointed if you fail, but you are doomed if you don't try. –Beverly Sills":"人生最重大的事情，就是有偉大的目標，和設法達到的決心。　－　哥德"),
                (this.props.current_lang === 'en'?"Remember no one can make you feel inferior without your consent. –Eleanor Roosevelt":"偉大或渺小取決於人的意志。　－　弗里德里希·席勒"),
                (this.props.current_lang === 'en'?"Life is what we make it, always has been, always will be. –Grandma Moses":"沒有什麼是不可能，不可能是意思是「不，可能。」　－　奧黛麗‧赫本"),
                (this.props.current_lang === 'en'?"The question isn’t who is going to let me; it’s who is going to stop me. –Ayn Rand":"一支2號鉛筆和一個夢想，就能帶你去任何你想去的地方。　－　喬伊斯·邁爾斯"),
                (this.props.current_lang === 'en'?"When everything seems to be going against you, remember that the airplane takes off against the wind, not with it. –Henry Ford":"生活沒有目標就像航海沒有指南針。　－　大仲馬"),
                (this.props.current_lang === 'en'?"It’s not the years in your life that count. It’s the life in your years. –Abraham Lincoln":"人們依靠行動而活，而不是想法而活。　—　哈里·愛默生·福斯迪克"),
                (this.props.current_lang === 'en'?"Change your thoughts and you change your world. –Norman Vincent Peale":"你不可能只是站著和注視海水，就以為能渡過海洋。不要讓自己耽溺於虛幻的希望之中。　－　泰戈爾"),
                (this.props.current_lang === 'en'?"Either write something worth reading or do something worth writing. –Benjamin Franklin":"要把事做起來，就停止說話，開始行動。　－　華特‧迪士尼"),
                (this.props.current_lang === 'en'?"Nothing is impossible, the word itself says, “I’m possible!” –Audrey Hepburn":"改變你的思想，就能改變你的命運。　－　諾曼·文森特·皮爾"),
                //(this.props.current_lang === 'en'?"":""),
            ],
            style: (this.props.style !== undefined ? this.props.style : 0),
        };

        this.handleHomePage = this.handleHomePage.bind(this);
    }

    /*componentWillMount() {
        Modal.setAppElement('body');
    }*/
    componentDidUpdate() {

        if(this.state.modalIsOpen){

            let percentage = this.state.percentage;
            var self = this;
            setTimeout(function(){
                if (percentage < 100) {
                    self.setState({percentage: percentage + 10});
                } else {
                    self.props.oncloseModal();
                }
            }, 500);
        }
    }
    handleHomePage() {
        window.location = `${process.env.REACT_APP_API_HOST}`;
    }
    render() {

        const customStyles = {
            overlay : {
                zIndex : 101, //overlay print download layer
                backgroundColor: 'rgba(14,19,24,.45)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            content : {
                position: 'relative',
                top                   : '0',
                left                  : '0',
                right                 : 'auto',
                bottom                : 'auto',
                marginRight           : '0',
                transform             : 'translate(0, 0)',
                backgroundColor: 'rgba(146,149,151, 0)',
                border:0,
                width                   : (this.state.style===1?'800px':'600px'),
                //boxShadow: '0 0 0 1px rgba(14,19,24,.07), 0 2px 18px rgba(14,19,24,.25)',
                padding: 0
            }
        };
        return (
            <div>
                <Modal
                    ariaHideApp={false}
                    isOpen={this.props.modalOpen}
                    onAfterOpen={this.props.onafterOpenModal}
                    onRequestClose={this.props.oncloseModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {this.props.isLoading ?
                        <div>

                            {(this.state.style===1?
                                <div className="modal_div2">
                                    <div className="title">
                                        {(this.props.current_lang === 'en' ? 'Your Design\'s on the way!' : '你的設計快有啦')}
                                    </div>
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    <div className="desc">{this.state.msg[(this.props.random==undefined?0:this.props.random)]}</div>
                                </div>:
                                <div>
                                    <div className="Modal_first_section" style={{height: '100px', textAlign: 'center', }}>
                                         {/*<img src={loadingIcon} width="200"/>*/}
                                        <div className="loader">
                                            <svg viewBox="0 0 80 80">
                                                <circle id="test" cx="40" cy="40" r="32"></circle>
                                            </svg>
                                        </div>

                                        <div className="loader triangle">
                                            <svg viewBox="0 0 86 80">
                                                <polygon points="43 8 79 72 7 72"></polygon>
                                            </svg>
                                        </div>

                                        <div className="loader">
                                            <svg viewBox="0 0 80 80">
                                                <rect x="8" y="8" width="64" height="64"></rect>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="modal_div1">
                                        <div className="title">
                                        {(this.props.callback_url && this.props.callback_url !== undefined)?
                                           this.props.current_lang === 'en' ? 'Nice Design! We are jumping back to JoinPrint now, this may take a while though. Thanks for waiting patiently :)' : '好設計！ 我們現在返回JoinPrint，可能需要一些時間。請耐心等待:)'
                                        :
                                           this.props.current_lang === 'en' ? "We recommend using Google Chrome in order to enjoy the best designing experience" : '要享受最佳的設計體驗，我們建議使用Google Chrome。'
                                        }
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*<div className="modal_div2">
                                <div className="title">
                                {(this.props.callback_url != "" && this.props.callback_url != undefined)?
                                   this.props.current_lang === 'en' ? 'Nice Design! We are jumping back to JoinPrint now, this may take a while though. Thanks for waiting patiently :)' : '好設計！ 我們現在返回JoinPrint，可能需要一些時間。請耐心等待:)'
                                :
                                   this.props.current_lang === 'en' ? "We recommend using Google Chrome in order to enjoy the best designing experience" : '要享受最佳的設計體驗，我們建議使用Google Chrome。'
                                }
                                </div>
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                <div className="desc">{this.state.msg[(this.props.random==undefined?0:this.props.random)]}</div>
                            </div>*/}
                            {/*<div className="Modal_second_section" style={{height: '50%', padding: '20px'}}>
                                <ProgressBar percentage={this.state.percentage}/>
                            </div>*/}
                        </div>
                        :
                        <div><div className="Modal_first_section" style={{height: '50%'}}>
                            <img src={banner} />
                        </div>
                        <div className="Modal_second_section" style={{height: '50%', padding: '20px'}}>
                            <div className="facebook_post_info_container">
                                <div className="facebook_post_info">
                                    <span>{this.props.current_lang === 'en' ? 'View Your Post Here' : '在這裡查看你的帖子'}</span>
                                    <span><a href={this.props.facebook_page_post_link} className="post_link" target="_blank" rel="noreferrer noopener noreferrer">
                                        {this.props.facebook_page_post_link}
                                    </a></span>
                                </div>
                            </div>
                            <div className="modal_btn_container">
                                <button onClick={this.props.oncloseModal} className="modal_btn_text_container modal_btn">{this.props.current_lang === 'en' ? 'Continue Editing' : '繼續編輯'} </button>
                                <button onClick={this.handleHomePage} className="modal_btn_text_container modal_btn modal_home_btn">{this.props.current_lang === 'en' ? 'Home Page' : '主頁'}</button>
                            </div>
                        </div>
                    </div> }
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    callback_url:state.callback_url,
    current_lang: state.current_lang,
    random: state.random,
});

export default connect(mapStateToProps)(ModalComponent);
