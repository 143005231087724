import React from 'react';
import classNames from "classnames";
//css
import './button.css';

export default class Button extends React.PureComponent {
    render() {
        const style = this.props.is_active ? "active_btn" : "";

        return (
            <button className={classNames("btn_outline_margin btn_outline_container btn_text_style btn_text_container ", style)} onClick={this.props.handleClick}>
                <span className="btn_title btn_title_fonts">{this.props.text}</span>
            </button>
        )
    }
}
