import React from 'react';
import classNames from "classnames";
//images
import align_center_icon from './align_center_icon.png';
import align_left_icon from './Align-left-text.png';
import align_right_icon from './Align-right-text.png';

import './textAlignmentPicker.css';
//view
import ImageComponent from '../Image';

export default class TextAlignmentPicker extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            alignment: props.alignment,
            showAlignPicker: false,
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            alignment: props.alignment,
        })
    }

    setAligment(alignment) {
        this.setState({alignment: alignment});
        this.props.applyPropertyChange({"textAlignment": alignment})
        this.setState({showAlignPicker: false});
    }

    toggleAlignmentPicker() {
        // this.setState({ showAlignPicker : !this.state.showAlignPicker });
        this.props.handleFontList(this.props.id)
    }

    render() {
        const style = this.props.showList && this.props.showList.listname === this.props.id ? "show_popup_contianer" : "";
        const is_active = !!(this.props.showList && this.props.showList.listname === this.props.id);
        return (
            <div className="toolbar_panel_container">
                <div id="align_box" key="align_picker" className="align_picker">
                    {this.props.alignment === "right" ?
                        <ImageComponent icon={align_right_icon} is_active={is_active}
                                        handleClick={() => this.toggleAlignmentPicker()}/>
                        : (this.props.alignment === "left" ?
                            <ImageComponent icon={align_left_icon} is_active={is_active}
                                            handleClick={() => this.toggleAlignmentPicker()}/> :
                            <ImageComponent icon={align_center_icon} is_active={is_active}
                                            handleClick={() => this.toggleAlignmentPicker()}/>)}
                </div>
                <div className={classNames("popup_contianer_align", style)}>
                    <div className="square_contianer s_contianer_align">
                        <div id="picker-panel" className="picker-panel">
                            <ImageComponent icon={align_left_icon} handleClick={() => this.setAligment('left')}
                                            is_active={this.props.alignment === "left"}/>
                            <ImageComponent icon={align_center_icon} handleClick={() => this.setAligment('center')}
                                            is_active={this.props.alignment === "center"}/>
                            <ImageComponent icon={align_right_icon} handleClick={() => this.setAligment('right')}
                                            is_active={this.props.alignment === "right"}/>
                        </div>
                    </div>
                </div>


                {/* <div className={classNames("toolbar_panel", {hide: !this.state.showAlignPicker})}>
                <div key="ToolBar Second Layer">
                    <div className="container_toolbar"><div className="left_container"><button className={classNames("container_btn_toolbar", {active : this.state.alignment == 'left'})} onClick={() => this.setAligment('left')}><span>Left</span></button></div>
                    <div className="center_container"><button className={classNames('container_btn_toolbar', {active: this.state.alignment == 'center'})} onClick={() => this.setAligment('center')}><span>Center</span></button></div>
                    <div className="right_container"><button className={classNames("container_btn_toolbar", {active:this.state.alignment == 'right'})} onClick={() => this.setAligment('right')}><span>Right</span></button></div></div>
                </div>
            </div>*/}
            </div>

        );
    }
}
