import React from 'react';
import classNames from "classnames";
//import {connect} from 'react-redux';

class SideMenuControl extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = ({
            id: this.props.id,
            ref: this.props.id,
            src: this.props.src,
            click: this.props.click,
            selected: this.props.selected,
            text: this.props.text,
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({text: props.text});
    }

    render() {
        //console.log(this.state.id+":"+this.state.selected);
        return (
            <div className={classNames(["MenuContainer", (this.state.selected ? "selected" : "")])}
                 onClick={this.state.click.bind(this)} ref={this.state.ref}>
                <img id={this.state.id} className="MenuElementSelector" src={this.state.src}/>
                <div className="menu_text">{this.state.text}</div>
            </div>
        );
    }

}

export default SideMenuControl;
