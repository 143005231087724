import React from 'react';
// import classNames from "classnames";
import {connect} from 'react-redux';

import './textInputControl.scss';
import {saveHistory, setEditTextElement, setElementProperty} from '../../../actions';

//import { getObjByHash } from '../../../utilities/canvasData'

//import ImageComponent from '../Image';

class TextInputControl extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            text: this.props.text
        }
    }

    handleChange(event) {
        if (this.props.activeElements.length === 1) {
            const re = new RegExp('(\\p{Emoji}\\uFE0F|\\p{Emoji_Modifier_Base}\\p{Emoji_Modifier}?|\\p{Emoji_Presentation})', 'u');
            let text = event.currentTarget.value;
            if(re.test(text)) {
                //alert('Sorry, we\'re not support emoji yet');

                text = event.currentTarget.value.replace(re, '');
            }

            this.setState({
                text
            });
            this.props.setElementProperty(this.props.activeElements[0].hash, {'textContent': text});
            this.props.setEditTextElement(null);
            this.props.saveHistory();
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.handleSave();
        }
    }

    handleSave() {
        if (this.props.handleSubmit) {
            this.props.handleSubmit();
        }
    }

    render() {
        if (this.props.editTextElement && this.props.editTextElement.singleLine) {
            return <div className="textInputControl">
                <input maxLength={this.props.editTextElement.maxChar?this.props.editTextElement.maxChar:15} onChange={this.handleChange.bind(this)} onKeyDown={this.handleKeyDown.bind(this)}
                       value={this.state.text} type="text"/>
            </div>
        }

        return (
            <div className="textInputControl header_outline">
                <textarea style={{width:'75%',display: 'inline-block',verticalAlign: 'bottom'}} onChange={this.handleChange.bind(this)} autoFocus={true} defaultValue={this.state.text}></textarea>
                <button style={{display: "inline-block",width: '20%',verticalAlign: 'top'}} onClick={this.handleSave.bind(this)} className="modal_btn_text_container modal_btn modal_home_btn">{this.props.current_lang === 'en' ? 'Save' : '儲存'} </button>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeElements: state.activeElements,
    editTextElement: state.editTextElement,
    current_lang: state.current_lang
});

const mapDispatchToProps = dispatch => ({
    setEditTextElement: (element) => dispatch(setEditTextElement(element)),
    setElementProperty: (elementHash, changes) => dispatch(setElementProperty(elementHash, changes)),
    saveHistory: () => dispatch(saveHistory())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextInputControl);
