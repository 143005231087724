import React from 'react';
//import classNames from "classnames";
import {connect} from 'react-redux';
//css and images
import './sharePermission.scss';
//import Victor from "victor";
//import $ from 'jquery';

//import Loading from "../Loading";

class SharePermission extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        //this.sendInvitationTeam = this.props.change;
        this.state = ({
            disabled: (this.props.projectPermission === "read"),
        });
    }

    /*
    onChangeEvent(e) {
      console.log(e.target.value);
    }
    */

    render() {

        const select_box = (
            <select className="team_share_select" id={this.props.id} onChange={this.props.change}
                    defaultValue={this.props.permission1} disabled={this.state.disabled} team_name={this.props.name}>

                <option value="">{this.props.current_lang === 'en' ? 'Not Shared' : '不分享'}</option>
                <option value="read">{this.props.current_lang === 'en' ? 'View only' : '只限閱覽'}</option>
                <option value="write">{this.props.current_lang === 'en' ? 'Editable' : '可編輯'} </option>

            </select>
        );

        var photo = (<img className={"photo_img"} src={this.props.photo}/>);
        if (!this.props.photo) {
            photo = (<img className={"photo_img"} style={{"visibility": "hidden"}} src={this.props.photo}/>);
        }

        return (
            <div className={"share_permission_container"}>
                <div className={"share_permission_photo"}>
                    {photo}
                </div>
                <div className={"share_permission_name"}>{this.props.name}</div>
                <div className={"share_permission_permission"}>
                    {select_box}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    projectPermission: state.projectPermission,
    permission: state.permission,
    current_lang: state.current_lang
});

export default connect(mapStateToProps)(SharePermission);
