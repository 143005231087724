import React, { Suspense, lazy } from 'react';
import classNames from "classnames";
import { connect } from 'react-redux';
//import $ from 'jquery';

import TextImg from '../SideControlPanel/text.png';
import ImageImg from '../SideControlPanel/element.png';
import CornersImg from '../SideControlPanel/background.png';
import LayoutImg from '../SideControlPanel/layout.png';
import MineImg from '../SideControlPanel/upload.png';
import CloseImg from './close.svg';

import TextElement from '../SideControlPanel/TextElement';
import LayoutElement from '../SideControlPanel/LayoutElement';
// import ImageElement from '../SideControlPanel/ImageElement';
// import BackgroundElement from '../SideControlPanel/BackgroundElement';
// import MineElement from '../SideControlPanel/MineElement';

import { setCurrentTab } from '../../actions/tabActions';

//css and images
import './tabBarPanel.scss';
import '../SideControlPanel/menu_open_arrow.png';
// import TextImg_selected from "../SideControlPanel/text_selected.png";
// import MineImg_selected from "../SideControlPanel/upload_selected.png";
// import ImageImg_selected from "../SideControlPanel/element_selected.png";
// import CornersImg_selected from "../SideControlPanel/background_selected.png";
// import LayoutImg_selected from "../SideControlPanel/layout_selected.png";

// const TextElement = lazy(() => import('../SideControlPanel/TextElement'));
// const LayoutElement = lazy(() => import('../SideControlPanel/LayoutElement'));
const ImageElement = lazy(() => import('../SideControlPanel/ImageElement'));
const BackgroundElement = lazy(() => import('../SideControlPanel/BackgroundElement'));
const MineElement = lazy(() => import('../SideControlPanel/MineElement'));

class TabBarPanel extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
          side_menu_control : [
            {src : LayoutImg, id : "layout", text:this.props.current_lang === 'en' ? 'Templates' : '模版'},
            {src : TextImg, id : "text", text:this.props.current_lang === 'en' ? "Text" : '文字'},
            {src : ImageImg, id : "image", text:this.props.current_lang === 'en' ? "Elements" : '素材'},
            {src : CornersImg, id : "background", text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
            {src : MineImg, id : "mine", text:this.props.current_lang === 'en' ? "Uploads" : '上載'},
          ],
          myImages: []
        };
      this.handleDismissClicked = this.handleDismissClicked.bind(this);
    }
    handleTabClicked(tab) {
        this.props.setCurrentTab(tab);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let side_menu_control=[];
        if (props.payment_term && props.select_from_team_id!==undefined ) {
            if ((props.payment_term==="monthly") && (props.select_from_team_id>0)){
                /*
                side_menu_control = [
                    {src : LayoutImg, id : "layout", text:this.props.current_lang === 'en' ? 'Templates' : '模版'},
                    {src : TextImg, id : "text", text:this.props.current_lang === 'en' ? "Text" : '文字'},
                    {src : ImageImg, id : "image", text:this.props.current_lang === 'en' ? "Elements" : '素材'},
                    {src : CornersImg, id : "background", text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
                    {src : MineImg, id : "mine", text:this.props.current_lang === 'en' ? "Uploads" : '上載'}
                ];
                */
                side_menu_control=[];
            }else if (this.props.project_target==="mp_order" ||this.props.project_target==="mp_product") {
                side_menu_control=[
                    {src : TextImg, id : "text", text:this.props.current_lang === 'en' ? "Text" : '文字'},
                    {src : ImageImg, id : "image", text:this.props.current_lang === 'en' ? "Elements" : '素材'},
                    {src : CornersImg, id : "background", text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
                    {src : MineImg, id : "mine", text:this.props.current_lang === 'en' ? "Uploads" : '上載'}
                ];
            }else{
                side_menu_control=[
                    {id : "layout", src : LayoutImg, text:this.props.current_lang === 'en' ? 'Templates' : '模版'},
                    {id : "text", src : TextImg, text:this.props.current_lang === 'en' ? "Text" : '文字'},
                    {id : "image", src : ImageImg, text:this.props.current_lang === 'en' ? "Elements" : '素材'},
                    {id : "background", src : CornersImg, text:this.props.current_lang === 'en' ? "Bkground" : '背景'},
                    {id : "mine", src : MineImg, text:this.props.current_lang === 'en' ? "Uploads" : '上載'},
                ];
            }
            this.setState({
                side_menu_control
            });
        }
    }

    handleDismissClicked() {
        this.props.setCurrentTab(null);
    }

    render() {
        let itemStyle = {
            width: (100 / this.state.side_menu_control.length) + "%"
        };
        return (
            <div className="tab-bar-panel">
                {this.state.side_menu_control.length >0 ?
                <div className="tab-bar">
                    {this.state.side_menu_control.map(menuItem => {
                        return (<div key={"tab-bar-item-" + menuItem.id} style={itemStyle} className={classNames(["tab-bar-item", {selected: menuItem.id == this.props.currentTab}])} onClick={this.handleTabClicked.bind(this, menuItem.id)}>
                            <img className="tab-bar-icon" src={menuItem.src} />
                            <div className="tab-bar-text">{menuItem.text}</div>
                        </div>)
                    })}
                </div>
                :""}
                {this.props.currentTab ? (<div className="tab-content-panel">
                    <a href="#" onClick={this.handleDismissClicked}><img className="close-btn" src={CloseImg}/></a>
                    {this.props.currentTab == 'text' ? (<div key="TextElement" className="tab-content-wrapper">
                    <TextElement onItemSelected={this.handleDismissClicked}></TextElement>
                    </div>) : null}
                    {this.props.currentTab == 'layout' ? (<div key="LayoutElement" className="tab-content-wrapper">
                    <LayoutElement onItemSelected={this.handleDismissClicked}></LayoutElement>
                    </div>) : null}
                    <Suspense fallback={<span></span>}>
                    {this.props.currentTab == 'image' ? (<div key="ImageElement" className="tab-content-wrapper">
                    <ImageElement onItemSelected={this.handleDismissClicked}></ImageElement>
                    </div>) : null}
                    {this.props.currentTab == 'background' ? (<div key="BackgroundElement" className="tab-content-wrapper">
                    <BackgroundElement onItemSelected={this.handleDismissClicked}></BackgroundElement>
                    </div>) : null}
                    {this.props.currentTab == 'mine' ? (<div key="MineElement" className="tab-content-wrapper">
                    <MineElement onItemSelected={this.handleDismissClicked} images={this.state.myImages} setImages={images => this.setState({myImages: images})}></MineElement>
                    </div>) : null}
                    </Suspense>
                </div>) : null}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        current_lang: state.current_lang,
        currentTab: state.tab.currentTab,
        payment_term: state.payment_term,
        select_from_team_id:state.select_from_team_id,
        team:state.team,
        project_target: state.project_target
    }
};

const mapDispatchToProps = dispatch => ({
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabBarPanel);
