import Victor from 'victor';

//import { getPageIndexByElementHash } from "./canvasData";
import { getBoundingBox } from "../math/frame";
import { ALL_INDEXES, vertexOfOriginRect } from "../math/rect";

const ROTATION_SNAP_TOLERANCE = 2;

const getSnapDelta = (snapInfo, value, hashs, snapTolerance) => {
  let snapDelta = null;

  snapInfo.forEach(snap =>  {
    if (hashs.includes(snap.element)) {
      return;
    }
    if ((snap.value - snapTolerance) <= value && value <= (snap.value + snapTolerance)) {
      if (snapDelta === null || Math.abs(snapDelta) > Math.abs(snap.value - value)) {
        snapDelta = snap.value - value;
      }
    }
  });
  return snapDelta;
};

const mergeSnapLines = (lines1, lines2) => {
  let xMap = [];
  let yMap = [];
  const parseLine = line => {
    if (line.x1 == line.x2) {
      if (!xMap[line.x1]) {
        xMap[line.x1] = [];
      }
      xMap[line.x1].push(line);
    }
    if (line.y1 == line.y2) {
      if (!yMap[line.y1]) {
        yMap[line.y1] = [];
      }
      yMap[line.y1].push(line);
    }
  }
  lines1.forEach(parseLine);
  lines2.forEach(parseLine);
  const mergeLines = lines => {
    return lines.reduce((result, line) => {
      if (!result) {
        return line;
      } else {
        return {
          dashed: result.dashed && result.dashed,
          x1: Math.min(result.x1, result.x2, line.x1, line.x2),
          x2: Math.max(result.x1, result.x2, line.x1, line.x2),
          y1: Math.min(result.y1, result.y2, line.y1, line.y2),
          y2: Math.max(result.y1, result.y2, line.y1, line.y2),
        }
      }
    })
  };
  xMap = Object.values(xMap).map(mergeLines);
  yMap = Object.values(yMap).map(mergeLines);
  return xMap.concat(yMap);
};

export const computeSnapRotation = rotation => {
  let snapDegs = [-180,-135,-90,-45,0,45,90,135,180];
  let deltaDeg = 0;
  snapDegs.forEach(snapDeg => {
    if (rotation > (snapDeg - ROTATION_SNAP_TOLERANCE) && rotation < (snapDeg + ROTATION_SNAP_TOLERANCE)) {
      deltaDeg = snapDeg - rotation;
    }
  });
  return deltaDeg;
};

export const computeSnap = (snapInfo, x, y, width, height, rotation, hashs, snapTolerance) => {
    let boundingBox = getBoundingBox(x - width / 2, y - height / 2, width, height, rotation);

    let center = new Victor(boundingBox.x + boundingBox.width / 2, boundingBox.y + boundingBox.height / 2);
    let halfSize = new Victor(boundingBox.width / 2, boundingBox.height / 2);

    let deltaX = null;
    let deltaY = null;

    ALL_INDEXES.forEach(i => {
      let vertex = vertexOfOriginRect(i, boundingBox.width, boundingBox.height);
      vertex.subtract(halfSize).add(center);
      const snapDeltaX = getSnapDelta(snapInfo.x, vertex.x, hashs, snapTolerance);
      const snapDeltaY = getSnapDelta(snapInfo.y, vertex.y, hashs, snapTolerance);
      if (deltaX === null || (Math.abs(deltaX) > Math.abs(snapDeltaX) && snapDeltaX !== null)) {
        deltaX = snapDeltaX;
      }
      if (deltaY === null || (Math.abs(deltaY) > Math.abs(snapDeltaY) && snapDeltaY !== null)) {
        deltaY = snapDeltaY;
      }
    });
    boundingBox.x += deltaX;
    boundingBox.y += deltaY;
    center.x += deltaX;
    center.y += deltaY;

    let snapSolidLines = [];
    let snapDashedLines = [];

    ALL_INDEXES.forEach(i => {
        let vertex = vertexOfOriginRect(i, boundingBox.width, boundingBox.height);
        vertex.subtract(halfSize).add(center);
        snapSolidLines = mergeSnapLines(snapSolidLines, snapInfo.x.filter(info => info.value == vertex.x && !info.dashed).reduce((result, info) => result.concat(info.lines), []));
        snapDashedLines = mergeSnapLines(snapDashedLines, snapInfo.x.filter(info => info.value == vertex.x && info.dashed).reduce((result, info) => result.concat(info.lines.map((line) => ({...line, x2: vertex.x, y2: vertex.y, dashed: true}))), []));
        snapSolidLines = mergeSnapLines(snapSolidLines, snapInfo.y.filter(info => info.value == vertex.y && !info.dashed).reduce((result, info) => result.concat(info.lines), []));
        snapDashedLines = mergeSnapLines(snapDashedLines, snapInfo.y.filter(info => info.value == vertex.y && info.dashed).reduce((result, info) => result.concat(info.lines.map((line) => ({...line, x2: vertex.x, y2: vertex.y, dashed: true}))), []));
    })
    const snapLines = mergeSnapLines(snapSolidLines, snapDashedLines);
    return {deltaX, deltaY, snapLines};
};

export const getSnapInfo = (pages, canvasPadding) => {
  let snapInfo = [];
  pages.forEach((page, index) => {
    let pageSnapInfo = {x: [], y: []};
    pageSnapInfo.x.push({
      value: 0,
      lines: [
        {x1: 0, y1: 0, x2: 0, y2: page.height}
        ]
      });
    pageSnapInfo.x.push({
      value: page.width,
      lines: [
        {x1: page.width, y1: 0, x2: page.width, y2: page.height}
        ]
      });
    pageSnapInfo.y.push({
      value: 0,
      lines: [
        {x1: 0, y1: 0, x2: page.height, y2: 0}
        ]
      });
    pageSnapInfo.y.push({
      value: page.height,
      lines: [
        {x1: 0, y1: page.height, x2: page.width, y2: page.height}
        ]
      });

    pageSnapInfo.x.push({
      value: canvasPadding,
      lines: [
        {x1: canvasPadding, y1: canvasPadding, x2: canvasPadding, y2: page.height - canvasPadding},
        {x1: page.width - canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        {x1: canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: canvasPadding},
        {x1: canvasPadding, y1: page.height - canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        ]
      });
    pageSnapInfo.x.push({
      value: page.width - canvasPadding,
      lines: [
        {x1: canvasPadding, y1: canvasPadding, x2: canvasPadding, y2: page.height - canvasPadding},
        {x1: page.width - canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        {x1: canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: canvasPadding},
        {x1: canvasPadding, y1: page.height - canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        ]
      });
    pageSnapInfo.y.push({
      value: canvasPadding,
      lines: [
        {x1: canvasPadding, y1: canvasPadding, x2: canvasPadding, y2: page.height - canvasPadding},
        {x1: page.width - canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        {x1: canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: canvasPadding},
        {x1: canvasPadding, y1: page.height - canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        ]
      });
    pageSnapInfo.y.push({
      value: page.height - canvasPadding,
      lines: [
        {x1: canvasPadding, y1: canvasPadding, x2: canvasPadding, y2: page.height - canvasPadding},
        {x1: page.width - canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        {x1: canvasPadding, y1: canvasPadding, x2: page.width - canvasPadding, y2: canvasPadding},
        {x1: canvasPadding, y1: page.height - canvasPadding, x2: page.width - canvasPadding, y2: page.height - canvasPadding},
        ]
      });
    pageSnapInfo.x.push({
      value: page.width / 2,
      lines: [
        {x1: page.width / 2, y1: 0, x2: page.width / 2, y2: page.height},
        ]
      });
    pageSnapInfo.y.push({
      value: page.height / 2,
      lines: [
        {x1: 0, y1: page.height / 2, x2: page.width, y2: page.height / 2},
        ]
      });

    page.elements.forEach(element => {
      let boundingBox = getBoundingBox(element.centerX - element.width / 2, element.centerY - element.height / 2, element.width, element.height, element.rotation);
      ALL_INDEXES.forEach(i => {
        let vertex = vertexOfOriginRect(i, boundingBox.width, boundingBox.height);
        let center = new Victor(boundingBox.x + boundingBox.width / 2, boundingBox.y + boundingBox.height / 2);
        let halfSize = new Victor(boundingBox.width / 2, boundingBox.height / 2);
        vertex.subtract(halfSize).add(center);
        pageSnapInfo.x.push({
          value: vertex.x,
          element: element.hash,
          dashed: true,
          lines: [
            {x1: vertex.x, y1: vertex.y, x2: null, y2: null},
            ]
          });
        pageSnapInfo.y.push({
          value: vertex.y,
          element: element.hash,
          dashed: true,
          lines: [
            {x1: vertex.x, y1: vertex.y, x2: null, y2: null},
            ]
          });
      })
    });
    snapInfo[index] = pageSnapInfo;
  });
  return snapInfo;
};
