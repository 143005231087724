import React from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";

//css
import './imageElement.css';

import {
  adaptImageData
} from '../../../utilities/canvasData';

class ImageElement extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            type: props.data.type,
            hash: props.data.hash,
            width: props.data.width,
            height: props.data.height,
            photoUrl: props.data.photoUrl,
            imageWidth: props.data.imageWidth,
            imageHeight: props.data.imageHeight,
            imageOriginLeft: props.data.imageOriginLeft,
            imageOriginTop: props.data.imageOriginTop,
            filter: props.data.filter,
            filterName: props.data.filterName
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            type: props.data.type,
            hash: props.data.hash,
            photoUrl: props.data.photoUrl,
            width: props.data.width,
            height: props.data.height,
            imageWidth: props.data.imageWidth,
            imageHeight: props.data.imageHeight,
            imageOriginLeft: props.data.imageOriginLeft,
            imageOriginTop: props.data.imageOriginTop,
            filter: props.data.filter,
            filterName: props.data.filterName
        })
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.zoomScale != nextProps.zoomScale) return true;
        for (var key in nextProps.data) {
            if (['preview','imageWidth','imageHeight', 'imageOriginLeft', 'imageOriginTop', 'filter', 'opacity', 'width', 'height', 'backgroundColor', 'photoUrl', 'lowResImage','flipX', 'flipY'].indexOf(key) !== -1) {
                if (nextProps.data[key] != this.props.data[key]) {
                    return true;
                }
            }

        }
        return false;
    }

    loadImageError(ev) {
        /*if (window.self !== window.top) { // Avoid luckorange error not sending referer
            return;
        }*/
        console.error('load image fail: ' + ev.target.src)
    }

    render() {
        // let self = this;
        let newImageData = null;
        if (this.props.data.preview && this.props.dragImageData) {
            newImageData = adaptImageData(this.state, this.props.data.preview);
        }
        let imgStyle = {
            width: (newImageData ? newImageData.imageWidth : this.state.imageWidth) * this.props.zoomScale,
            height: (newImageData ? newImageData.imageHeight : this.state.imageHeight) * this.props.zoomScale,
            left: this.props.data.flipX ? 'auto' : ((newImageData ? newImageData.imageOriginLeft : this.state.imageOriginLeft) * this.props.zoomScale),
            right: this.props.data.flipX ? ((newImageData ? newImageData.imageOriginLeft : this.state.imageOriginLeft) * this.props.zoomScale) : 'auto',
            top: this.props.data.flipY ? 'auto' : ((newImageData ? newImageData.imageOriginTop : this.state.imageOriginTop) * this.props.zoomScale),
            bottom: this.props.data.flipY ? ((newImageData ? newImageData.imageOriginTop : this.state.imageOriginTop) * this.props.zoomScale) : 'auto',
            filter: this.state.filter,
            opacity: this.props.data.opacity,
            transform: `scaleX(${this.props.data.flipX ? -1 : 1}) scaleY(${this.props.data.flipY ? -1 : 1})`
        };
        let bgStyle = {
            width: this.props.data.width * this.props.zoomScale,
            height: this.props.data.height * this.props.zoomScale,
            backgroundColor: this.props.data.backgroundColor,
        };
        let figure_class = this.state.filterName;
        return (
            <div >
                <div style={bgStyle}></div>
                {Math.round(imgStyle.width)>1 && Math.round(imgStyle.height)>1?<figure className={figure_class}>
                    <img
                  className={classNames("image_content", {hide: !(newImageData ? (newImageData.lowResImage ? newImageData.lowResImage : (newImageData.photoUrl + (newImageData.photoUrl.indexOf('?')?'&':'?') + 'width='+this.props.data.width * this.props.zoomScale)) : this.state.photoUrl)})}
                  style={imgStyle} onError={this.loadImageError}
                  src={newImageData ? (newImageData.lowResImage ? newImageData.lowResImage : newImageData.photoUrl) : this.state.photoUrl}/>
                </figure>:''}
            </div>
        )
    }
}
ImageElement.defaultProps = {
    imageOriginLeft: 0,
    imageOriginTop: 0,
};

const mapStateToProps = state => ({
    // zoomScale: state.zoomScale,
    dragImageData: state.dragImageData
});

export default connect(
    mapStateToProps
    )(ImageElement);
