import React from 'react';
import classNames from "classnames";
import {connect} from 'react-redux';
//import Victor from "victor";
//import $ from 'jquery';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import './../ProjectControlPanel/cancel.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {
    resetCanvas,
    resizePage,
    setAuthStatus,
    setZoomScaleByContainerSize,
    toggleItemVisibility,
    toggleShowMargin,
    toggleShowPrintBleed
} from '../../actions';
//import products from '../../demoData/demoProducts';
import Cookies from 'universal-cookie';
//css and images
import './fileDropdown.scss';

class FileDropdown extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
        this.handleMoveFolderClicked = props.handleMoveFolderClicked;
    }

    handleResizeClicked() {
        this.props.toggleItemVisibility('resizePrompt');
    }

    handleCreateProjectClicked() {
        //this.handleResizeClicked();
        //this.props.resetCanvas();
        window.location = `${process.env.REACT_APP_API_HOST}`;
    }

    login() {

        const cookies = new Cookies();
        var api_token = cookies.get('api_token');
        var code = cookies.get('code');

        var self = this;

        let popupWindow = null;
        let callback = status => {
            //console.log("callback B");
            if (status == 200) {
                self.props.setAuthStatus("member");
            }
        }
        let checkWindowClosed = setInterval(() => {
            if (popupWindow && popupWindow.closed) {
                popupWindow = null;
                clearInterval(checkWindowClosed);
                callback("Failed");
            }
        }, 1000);
        window.addEventListener('message', event => {
            if (event.origin.indexOf(`${process.env.REACT_APP_API_HOST}`) !== -1) {
                if (event.data.action == 'authCallback') {
                    //console.log("Auth callback A: " + event.data.data.status)
                    if (popupWindow) {
                        popupWindow.close();
                        popupWindow = null;
                        clearInterval(checkWindowClosed);
                    }
                    callback(event.data.data.status);
                }
            }
        }, {once: true})
        popupWindow = window.open(`${process.env.REACT_APP_API_HOST}` + '/design/benefits/' + code + '/' + api_token, 'Login', 'height=600,width=700,resizable=yes,toolbar=no,menubar=no,location=no,status=no');
    }

    render() {

        var saveDesign = (<div></div>);
        if (this.props.access_level >= 70) {
            saveDesign = ([
                <a href="#" key="saveToText" onClick={this.props.handleDesingerSave.bind(this, "text")}>
                    <li>{this.props.current_lang === 'en' ? 'Save to Text' : '保存至文字'}</li>
                </a>,
                <a href="#" key="saveToTemplate" onClick={this.props.handleDesingerSave.bind(this, "layout")}>
                    <li>{this.props.current_lang === 'en' ? 'Save to Template' : '保存至模板'}</li>
                </a>,
                <hr key="hr1"/>
            ]);
        }

        var margin = (<div></div>);
        if (`${process.env.REACT_APP_STAGE}` != "production") {
            margin = ([
                (this.props.project_target == "mp_order" ? '' :
                    <a href="#" key="margin" onClick={this.props.toggleShowMargin.bind(this)}>
                        <li>{this.props.current_lang === 'en' ? 'Show Margin' : '顯示邊緣'}<span
                            className={classNames("icon", {hide: !this.props.isShowingMargin})}><FontAwesomeIcon
                            icon={faCheck}/></span></li>
                    </a>),
                <a href="#" key="bleed" onClick={this.props.toggleShowPrintBleed.bind(this)}>
                    <li>{this.props.current_lang === 'en' ? 'Show print bleed' : '顯示出血位'} <span
                        className={classNames("icon", {hide: !this.props.isShowingPrintBleed})}><FontAwesomeIcon
                        icon={faCheck}/></span></li>
                </a>
            ]);
        } else {
            margin = ([
                <a href="#" key="bleed" onClick={this.props.toggleShowPrintBleed.bind(this)}>
                    <li>{this.props.current_lang === 'en' ? 'Show print bleed' : '顯示出血位'}<span
                        className={classNames("icon", {hide: !this.props.isShowingPrintBleed})}><FontAwesomeIcon
                        icon={faCheck}/></span></li>
                </a>
            ]);
        }

        //var resize = (<div></div>);
        /*if (`${process.env.REACT_APP_STAGE}` != "production") {
            resize = (<a href="#" onClick={this.handleResizeClicked.bind(this)}>
                <li>Resize</li>
            </a>);
        } else {
            resize = (<a href="#" onClick={this.handleResizeClicked.bind(this)}>
                <li>Resize</li>
            </a>);
        }*/

        var saveAndNext = (<div></div>);
        if (this.props.access_level == 0 || this.props.access_level == 99) {
            saveAndNext = (
                <a href="#" onClick={this.props.handleSaveAndNext.bind(this)}>
                    <li>
                        {this.props.current_lang === 'en' ? (this.props.authStatus && this.props.authStatus == 'guest' ? 'Save and Next' : 'Save') : (this.props.authStatus && this.props.authStatus == 'guest' ? '儲存及下一步' : '儲存')}
                    </li>
                </a>
            );
        }
        var login = (<div></div>);
        if (this.props.authStatus == undefined || this.props.authStatus == "guest") {
            login = (
                <a href="#" onClick={this.login.bind(this)}>
                    <li>{this.props.current_lang === 'en' ? 'Login' : '登入'}</li>
                </a>
            );
        }

        var folder = (<div></div>);
        if (this.props.authStatus == "member") {
            folder = (
                <a href="#" onClick={this.handleMoveFolderClicked.bind(this)}>
                    <li>{this.props.current_lang === 'en' ? 'Move to Folder' : '移動至文件'}</li>
                </a>
            );
        }

        return (
            <div className={classNames("file_dropdown", {visible: this.props.visible})}>
                <div style={{display: 'block'}}>
                    <div className={"project_summary"}>
                        <div className={"project_type"}>{this.props.product ? this.props.product.title : 'Custom'}</div>
                        <div className={"project_desc"}>{this.props.width}px x {this.props.height}px</div>
                    </div>
                    <hr/>
                    <ul>
                        {(this.props.project_target == "mp_order" || this.props.callback_url) ? '' :
                            <a href="#" onClick={this.handleCreateProjectClicked.bind(this)}>
                                <li>{this.props.current_lang === 'en' ? 'Create new Design' : '創建新設計'}</li>
                            </a>}
                        {margin}
                        <a href="#" onClick={this.props.resetCanvas.bind(this)}>
                            <li>{this.props.current_lang === 'en' ? 'Clear Canvas' : '清除畫面'}</li>
                        </a>
                        <hr/>
                        {this.props.project_target == "mp_order" ? '' : <div style={{backgroundColor: "#d59bc4"}}>
                            {saveDesign}
                        </div>}
                        {saveAndNext}
                        {login}
                        {/*{resize}*/}
                        {this.props.project_target == "mp_order" ? '' : folder}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    callback_url: state.callback_url,
    project_target: state.project_target,
    isShowingMargin: state.isShowingMargin,
    isShowingPrintBleed: state.isShowingPrintBleed,
    width: state.pages.length ? state.pages[0].width : 0,
    height: state.pages.length ? state.pages[0].height : 0,
    access_level: state.access_level,
    //username: state.username,
    product: state.productList ? state.productList.find(product => product.width == (state.pages.length ? state.pages[0].width : 0) && product.height == (state.pages.length ? state.pages[0].height : 0)) : null,
    //showMoveFolderBox: state.itemsVisibility['moveFolderBox'],
    authStatus: state.authStatus,
    current_lang: state.current_lang
});

const mapDispatchToProps = dispatch => ({
    resizePage: (width, height) => dispatch(resizePage(width, height)),
    setZoomScaleByContainerSize: (width, height) => dispatch(setZoomScaleByContainerSize(width, height, 'fit')),
    resetCanvas: () => dispatch(resetCanvas()),
    toggleShowMargin: () => dispatch(toggleShowMargin()),
    toggleShowPrintBleed: () => dispatch(toggleShowPrintBleed()),
    toggleItemVisibility: (itemCode) => dispatch(toggleItemVisibility(itemCode)),
    setAuthStatus: (authStatus) => dispatch(setAuthStatus(authStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDropdown);
