import React from 'react';

//css
import './delete.css';
import delete_icon from './Delete-item.svg';

export default class Page extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state ={
            is_locked: props.is_locked
        }
    }

    render() {
        return (
            <div id="delete_box" className={(this.props.is_locked)?'':'title_box' } >
                <img className= {(this.props.is_locked)?'disable_delete_icon':'delete_icon' } src={delete_icon} onClick={this.props.handleDeleteElement} id="showAlignPicker" />
            </div>
    )
    }
}
