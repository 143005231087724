export const loadDefault = () => ({
  type: 'LOAD_DEFAULT'
});

export const loadCanvasData = data => ({
  type: 'LOAD_CANVAS_DATA',
  data
});

export const addNewPage = data => ({
    type: 'ADD_NEW_PAGE',
    data
});

export const deleteCurrentPage = (data, idx) => ({
    type: 'DELETE_PAGE',
    data,
    idx
});

export const deleteActiveElement = () => ({
    type: 'DELETE_ELEMENT'
});

export const setActiveElement = elementHashs => ({
  type: 'SET_ACTIVE_ELEMENT_BY_HASH',
  elementHashs
});

export const clearActiveElements = () => ({
  type: 'CLEAR_ACTIVE_ELEMENT'
});

export const setActiveElementProperty = (changes) => ({
  type: 'SET_ACTIVE_ELEMENT_PROPERTY',
  changes
});

export const setElementProperty = (elementHash, changes, applyFromUserChanges = false) => ({
  type: 'SET_ELEMENT_PROPERTY',
  elementHash,
  changes,
  applyFromUserChanges
});

export const setGrouppedElementProperty = grouppedChanges => ({
  type: 'SET_GROUPPED_ELEMENT_PROPERTY',
  grouppedChanges
});

export const addElementByData = (data, point, page) => ({
  type: 'ADD_ELEMENT_BY_DATA',
  data,
  point,
  page
});

export const saveProject = (data, saveId, projectName, width, height, permission, fonts_json, brand_token, partnership_link,recentlyUsedImages, recentlyUsedPhotos, callback) => ({
  type: 'SAVE_PROJECT',
  data,
  saveId,
  projectName,
  width,
  height,
  permission,
  fonts_json,
  brand_token,
  partnership_link,
  recentlyUsedImages,
  recentlyUsedPhotos,
  callback
});

export const createProject = callback => ({
  type: 'CREATE_PROJECT',
  callback
});

export const loadProject = (code, callback) => ({
  type: 'LOAD_PROJECT',
  code,
  callback
});

export const loadPrintOnly = (code, callback) => ({
  type: 'LOAD_PRINT_ONLY',
  code : code,
  callback : callback
});

export const exportProject = (param, callback) => ({
  type: 'EXPORT_PROJECT',
  param,
  callback
});

export const setProjectLoading = isLoading => ({
  type: 'SET_PROJECT_LOADING',
  isLoading
});

export const setFilterMode = () => ({
    type: 'SET_FILTER_MODE'
});

export const setAdjustMode = () => ({
    type: 'SET_ADJUST_MODE'
});

export const setCropMode = () => ({
  type: 'SET_CROP_MODE'
});

export const setColorPickerMode = () => ({
    type: 'SET_COLOR_PICKER_MODE'
});

export const setDefaultMode = () => ({
  type: 'SET_DEFAULT_MODE'
});

export const setTransformMode = () => ({
  type: 'SET_TRANSFORM_MODE'
});

export const setTextEditMode = () => ({
  type: 'SET_TEXT_EDIT_MODE'
});

export const setImageCropData = data => ({
  type: 'SET_IMAGE_CROP_DATA',
  data
});

export const setActiveElementIdx = () => ({
    type: 'SET_ACTIVE_ELEMENT_INDEX'
});

export const reorderData = move => ({
    type: 'REORDER_ARRAY',
    move
});

export const updateSnapInfo = () => ({
  type: 'UPDATE_SNAP_INFO'
});

export const copyElement = () => ({
    type: 'COPY_ELEMENT'
});

export const setActivePageProperty = changes => ({
    type: 'SET_ACTIVE_PAGE_PROPERTY',
    changes
})

// @deprecated
/*
export const deleteUploadedImg = (image_id, callback) => ({
    type: 'DELETE_UPLOADED_IMG',
    image_id,
    callback
});
*/

export const uploadMine = (file, callback, fail = null) => ({
    type: 'UPLOAD_MINE',
    file,
    callback,
    fail
});

export const getDesignElement = (element_type, image_type, category_id, keyword, offset, limit, other, brand_token, callback, hash) => ({
    type: 'GET_DESIGN_ELEMENT',
    element_type,
    image_type,
    category_id,
    keyword,
    offset,
    limit,
    other,
    brand_token,
    callback,
    hash
});



export const getRecentlyUsedImages = (category_id, user_id, offset1, limit1,callback) => ({
    type: 'GET_RECENTLY_USED_IMAGES',
    category_id,
    user_id,
    offset1,
    limit1,
    callback
});

export const setRecentUsedImagesList = (recentlyUsedImages, recentlyUsedPhotos) => ({
  type: 'SET_RECENT_USED_IMAGE_LIST',
  recentlyUsedImages,
  recentlyUsedPhotos
});

export const updateRecentUsedImages = (recentSelectId, imageType ) => ({
  type: 'UPDATE_RECENT_USED_IMAGES',
  recentSelectId,
  imageType
});

export const saveElementId = (element_id, photo_element_ids, user_id, callback) => ({
    type: 'SAVE_ELEMENT_ID',
    element_id,
    photo_element_ids,
    user_id,
    callback
});

export const getDesignCategory = (element_type, category_id, offset, limit, brand_token, callback) => ({
    type: 'GET_DESIGN_CATEGORY',
    element_type,
    category_id,
    offset,
    limit,
    brand_token,
    callback
});

export const showFontList = listname => ({
    type: 'SHOW_FONT_LIST',
    listname
});

export const showImageFilter = listname => ({
    type: 'SHOW_IMAGE_FILTER',
    listname
});

export const createVirtualGroup = hashs => ({
    type: 'CREATE_VIRTUAL_GROUP',
    hashs
});

export const ungroupVirtualGroup = () => ({
    type: 'UNGROUP_VIRTUAL_GROUP'
});

export const toggleActualGroup = (hash, isActual) => ({
    type: 'TOGGLE_ACTUAL_GROUP',
    hash,
    isActual
});

export const alignElements = alignment => ({
    type: 'ALIGN_ELEMENT_TO_PAGE',
    alignment
});

export const setZoomScale = zoomScale => ({
    type: 'SET_ZOOM_SCALE',
    zoomScale
});

export const setZoomScaleByContainerSize = (width, height, mode, bleed) => ({
    type: 'SET_ZOOM_SCALE_BY_CONTAINER_SIZE',
    width,
    height,
    mode,
    bleed
});

export const showSideBar = () => ({
    type: 'SHOW_SIDE_BAR'
});

export const closeSideBar = () => ({
    type: 'HIDE_SIDE_BAR'
});

export const movePageUp = hash => ({
    type: 'MOVE_PAGE_UP',
    hash
});

export const movePageDown = hash => ({
    type: 'MOVE_PAGE_DOWN',
    hash
});

export const duplicatePage = hash => ({
    type: 'DUPLICATE_PAGE',
    hash
});

export const saveHistory = () => ({
    type: 'SAVE_HISTORY'
});

export const undoHistory = () => ({
    type: 'UNDO_HISTORY'
});

export const redoHistory = () => ({
    type: 'REDO_HISTORY'
});

export const alignSaveId = () => ({
    type: 'ALIGN_SAVE_ID'
});

export const setPrintOnlyLoaded = () => ({
    type: 'SET_PRINT_ONLY_LOADED'
});

export const setFocusPage = pageId => ({
    type: 'SET_FOCUS_PAGE',
    pageId
});

export const setProjectCode = projectCode => ({
    type: 'SET_PROJECT_CODE',
    projectCode
});
export const saveDesignerTemplate = (code, page, saveType, width, height, callback) => ({
    type: 'SAVE_DESIGNER_TEMPLATE',
    code,
    page,
    saveType,
    width,
    height,
    callback
});
export const clearAllPopup = () => ({
    type: 'CLEAR_ALL_POPUP'
});

export const bindProject = callback => ({
    type: 'BIND_PROJECT',
    callback
});

export const replaceFocusPageBackgroundColor = color => ({
    type: 'REPLACE_FOCUS_PAGE_BACKGROUND_COLOR',
    color
});

export const toggleItemVisibility = itemCode => ({
    type: 'TOGGLE_ITEM_VISIBILITY',
    itemCode
});

export const setShowDropzone = visibility => ({
    type: 'SET_SHOW_DROPZONE',
    visibility
});
export const setProjectTarget = project_target => ({
    type: 'SET_PROJECT_TARGET',
    project_target
});
export const setAccessLevel = access_level => ({
    type: 'SET_ACCESS_LEVEL',
    access_level
});
export const instantUpload = (hash, preview_url, minHeight, minWidth, filterName) => ({
    type: 'SHOW_POPUP_UPLOAD',
    hash, preview_url, minHeight, minWidth, filterName
});
export const setPermission = permission => ({
    type: 'SET_PERMISSION',
    permission
});
export const setEngineReady = engineReady => ({
    type: 'SET_ENGINE_READY',
    engineReady
});
export const setProductName = productName => ({
    type: 'SET_PRODUCT_NAME',
    productName
});
export const setUserName = username => ({
    type: 'SET_USERNAME',
    username
});
export const setPaymentTerm = payment_term => ({
    type: 'SET_PAYMENT_TERM',
    payment_term
});
export const setSelectFromTeamID = (select_from_team_id,team) => ({
    type: 'SET_SELECT_FROM_TEAM_ID',
    select_from_team_id,
    team
});
export const setProjectName = project_name => ({
    type: 'SET_PROJECT_NAME',
    project_name
});
export const setCallbackUrl = callback_url => ({
    type: 'SET_CALLBACK_URL',
    callback_url
});
export const setMainUrl = main_url => ({
    type: 'SET_MAIN_URL',
    main_url
});
export const setSaveUrl = save_url => ({
    type: 'SET_SAVE_URL',
    save_url
});
export const setPartnershipLink = partnership_link => ({
    type: 'SET_PARTNERSHIP_LINK',
    partnership_link
});
export const setPartnershipParam = partnership_param => ({
    type: 'SET_PARTNERSHIP_PARAM',
    partnership_param
});
export const setProjectWaterMark = has_watermark => ({
    type: 'SET_PROJECT_WATERMARK',
    has_watermark
});
export const setIsPrintable = is_printable => ({
    type: 'SET_IS_PRINTABLE',
    is_printable
});
export const setMyFavouriteFilter = () => ({
    type: 'SET_MY_FAVOURITE_FILTER',
});
export const setSolidPickerFilter = () => ({
    type: 'SET_SOLID_COLOR_PICKER_MODE',
});
export const resizePage = (width, height) => ({
    type: 'RESIZE_PAGE',
    width,
    height
})

export const resetCanvas = () => ({
    type: 'RESET_CANVAS'
})

export const resetFocusPage = () => ({
    type: 'RESET_FOCUS_PAGE'
})

export const toggleShowMargin = () => ({
    type: 'TOGGLE_SHOW_MARGIN'
})

export const toggleShowPrintBleed = () => ({
    type: 'TOGGLE_SHOW_PRINT_BLEED'
})

export const showPrintBleed = () => ({
    type: 'SHOW_PRINT_BLEED'
})

export const hidePrintBleed = () => ({
    type: 'HIDE_PRINT_BLEED'
})

export const setFocusEditText = hash => ({
  type: 'SET_FOCUS_EDIT_TEXT',
  hash
});

export const savePreview = (width, height, callback) => ({
    type: 'SAVE_PREVIEW',
    width,
    height,
    callback
});

export const setDragImageData = imageData => ({
  type: 'SET_DRAG_IMAGE_DATA',
  imageData
});

export const applyDragImagePreview = (hash, imageData) => ({
  type: 'APPLY_DRAG_IMAGE_PREVIEW',
  hash,
  imageData
});

export const createBackgroundAndApplyDragImagePreview = (imageData, width, height) => ({
  type: 'CREATE_BG_APPLY_DRAG_IMAGE_PREVIEW',
  imageData,
  width,
  height
});

export const showUploadeditemactionbox = actionBoxId => ({
    type: 'SHOW_UPLOAD_ITEM_ACTION_BOX',
    actionBoxId
});

export const checkUserVerifyStatus = callback => ({
    type: 'CHECK_USER_VERIFY_STATUS',
    callback
});
export const checkUserSession = () => ({
    type: 'CHECK_USER_SESSION'
});
export const auth = (token, callback) => ({
    type: 'AUTH',
    token,
    callback
});

export const promptLogin = callback => ({
    type: 'PROMPT_LOGIN',
    callback
});

export const setAuthStatus = authStatus => ({
    type: 'SET_AUTH_STATUS',
    authStatus
});

export const setVerifyStatus = (verifyStatus, verifyNickname, verifyEmail) => ({
    type: 'SET_VERFIFY_STATUS',
    verifyStatus,
    verifyNickname,
    verifyEmail
});
export const setAlertBox = alertBoxContent => ({
    type: 'SET_ALERT_BOX',
    alertBoxContent
});
export const setProjectShare = (email, permission, scope, callback) => ({
  type: 'SET_PROJECT_SHARE',
  email,
  permission,
  scope,
  callback
});

export const setProjectShareTeam = (team_id, permission, scope, callback) => ({
  type: 'SET_PROJECT_SHARE_TEAM',
  team_id,
  permission,
  scope,
  callback
});

export const getProjectShare = callback => ({
  type: 'GET_PROJECT_SHARE',
  callback
});

export const promptSlackLogin = callback => ({
    type: 'PROMPT_SLACK_LOGIN',
    callback
});

export const getUsedColorList = callback => ({
    type: 'TEXT_USED_COLOR_LIST',
    callback
});

export const setColorProperty = (property, color, svgOriginalcolor) => ({
    type: 'SET_COLOR_PROPERTY',
    property,
    color,
    svgOriginalcolor
});

export const getFolderList = callback => ({
    type: 'GET_FOLDER_LIST',
    callback
});

export const setProjectFolder = (folder_id, callback) => ({
    type: 'SET_PROJECT_FOLDER',
    folder_id,
    callback
});


export const checkSocialMediaToken = (media_type, media_id, callback) => ({
    type: 'CHECK_SOCIAL_MEDIA_TOKEN',
    media_type,
    media_id,
    callback
});

export const slackUploadFile = (token, text, channels, file_title, callback) => ({
    type: 'SLACK_UPLOAD_FILE',
    token,
    text,
    channels,
    file_title,
    callback
});

export const getCustomFonts = (username, callback) => ({
    type: 'GET_CUSTOM_FONTS',
    username,
    callback
});

export const addNewText = () => ({
    type: 'ADD_NEW_TEXT'
});

export const setCopyData = () => ({
    type: 'SET_COPY_DATA'
});

export const pasteCopyData = () => ({
    type: 'PASTE_COPY_DATA'
});

export const setCustomFonts = data => ({
    type: 'SET_CUSTOM_FONTS',
    data
});

export const setCustomColor = data => ({
    type: 'SET_CUSTOM_COLORS',
    data
});

export const setBrandToken = data => ({
    type: 'SET_BRAND_TOKEN',
    data
});

export const loadBrandKit = data => ({
    type: 'LOAD_BRAND_KIT',
    data
});

export const loadProjectFonts = data => ({
    type: 'LOAD_PROJECT_FONTS',
    data
});

export const loadProductList = data => ({
    type: 'LOAD_PRODUCT_LIST',
    data
});

export const setMyFavourite = (element_id, star) =>({
    type: 'SET_MY_FAVOURITE',
    element_id,
    star
});
export const closeElementMenu = () => ({
  type: 'CLOSE_ELEMENT_MENU'
});
export const changeElementMenu = () => ({
    type: 'CHANGE_ELEMENT_MENU'
});

export const sendVerificationEmail = callback => ({
    type: 'SEND_VERIFICATION_EMAIL',
    callback
});

export const setElementAsBackground = hash => ({
  type: 'SET_ELEMENT_AS_BACKGROUND',
  hash
})

export const cleanChildGroup = groupElement => ({
  type: 'CLEAN_CHILD_GROUP',
  groupElement
})

export const setProjectloaded = is_loaded => ({
  type: 'SET_PROJECT_IS_LOADED',
  is_loaded
})

export const addFolder = (folder_name, callback) => ({
  type: 'ADD_FOLDER',
  folder_name,
  callback
})

export const currentLang = lang => ({
  type: 'SET_LANG',
  lang
})

export const setLockActiveElements = shouldLock => ({
    type: 'LOCK_ACTIVE_ELEMENTS',
    shouldLock
});

export const flipImage = (hash, direction) => ({
    type: 'FLIP_ELEMENT',
    hash,
    direction
});

export const setModalRandom = random => ({
  type: 'SET_MODAL_RANDOM',
  random
})

export const setPrintDesignOption = (option, variation) => ({
    type: 'SET_PRINT_DESIGN_OPTION',
    option,
    variation
});

export const setCurrentProjectOption = (option, variation) => ({
    type: 'SET_CURRENT_PROJECT_OPTION',
    option,
    variation
});

export const setPresetDesignOption = (option, label) => ({
    type: 'SET_PRESET_DESIGN_OPTION',
    option,
    label
});

export const goPreviewPage = page => ({
    type: 'GO_PREVIEW_PAGE',
    page
});

export const goPreviewPageByTag = tag => ({
    type: 'GO_PREVIEW_PAGE_BY_TAG',
    tag
});

export const togglePreviewMin = () => ({
    type: 'TOGGLE_PREVIEW_MIN',
});

export const applyTextForActiveElements = () => ({
    type: 'APPLY_TEXT_FOR_ACTIVE_ELEMENTS',
});

export const setProxyEvent = proxyEvent => ({
    type: 'SET_PROXY_EVENT',
    proxyEvent
});

export const setEditTextElement = element => ({
    type: 'SET_EDIT_TEXT_ELEMENT',
    element
});

export const setProxyHoverElementHash = hash => ({
    type: 'SET_PROXY_HOVER_ELEMENT_HASH',
    hash
});

export const setWordwrapHint = (hash, hint) => ({
    type: 'SET_WORD_WRAP_HINT',
    hash,
    hint
});

export const applyWordwrapHint = () => ({
    type: 'APPLY_WORD_WRAP_HINT',
});

export const duplicateProject = (code, changes, variations, callback) => ({
    type: 'DUPLICATE_PROJECT',
    code,
    changes,
    variations,
    callback
});

/**
 * @deprecated move to setCustomTextProps(hash, {textContent})
 * @param hash
 * @param customText
 * @returns {{customText: *, type: string, hash: *}}
 */
export const setCustomText = (hash, customText) => ({
    type: 'SET_CUSTOM_TEXT',
    hash,
    customText,
});

export const getImages = () => ({
    type: 'GET_IMAGES',
});

export const setCustomTextProps = (hash, props) => ({
    type: 'SET_CUSTOM_TEXT_PROPS',
    hash,
    props,
});

export const setCustomImage = (hash, changes) => ({
    type: 'CHANGE_PHOTO_URL',
    hash,
    changes,
});

export const setFontSize = (hash, fontSize) => ({
    type: 'SET_CUSTOM_FONT_SIZE',
    hash,
    fontSize,
});

export const toggleCommonBack = hash => ({
    type: 'TOGGLE_COMMON_BACK',
    hash
});

export const setCustomArtwork = (artwork, page) => ({
    type: 'SET_CUSTOM_ARTWORK',
    artwork,
    page
});
