import React from 'react';
import classNames from "classnames";
import {connect} from 'react-redux';

import './Transparency.css';
import {resolveGroupElement} from './../../../utilities/canvasData';
//view
//import TextComponent from '../List';
//import ButtonComponent from "../Button";
import transparent_icon from './transparent.png';

class Transparency extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.state = {
            position: props.alignment,
            showPositionPicker: false,
            value: props.targetElement.opacity ? props.targetElement.opacity * 100 : 100
        };
        this.handlePicker = this.handlePicker.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        var isDisableForwardbtn = (props.elLength - 1) === props.elementIdx;
        var isDisableBackwardbtn = props.elementIdx === 0;
        this.setState({
            alignment: props.alignment,
            value: props.targetElement.opacity ? props.targetElement.opacity * 100 : 100,
            forwardButtonDisabled: isDisableForwardbtn,
            backwardButtonDisabled: isDisableBackwardbtn
        });
    }

    setPosition(position) {
        this.setState({position: position});
        this.props.handlePosition({"position": position});
    }

    toggleAlignmentPicker() {
        this.setState({showPositionPicker: !this.state.showPositionPicker});
    }

    handleChange(e) {
        this.setState({value: e.target.value});
        let val = e.target.value / 100;
        this.props.applyPropertyChange({"opacity": val});
        this.props.handleFontList(this.props.id);
    }

    handlePicker() {
        this.props.handleFontList(this.props.id);
    }

    render() {
        const style = this.props.showList && this.props.showList.listname === this.props.id ? "show_popup_contianer" : "";
        //const is_active = !!(this.props.showList && this.props.showList.listname === this.props.id);
        return (
            <div className="toolbar_panel_container">
                <div className="title_box">
                    <img className="transparent_icon" src={transparent_icon} onClick={this.handlePicker}/>
                </div>
                <div className={classNames("popup_contianer_align range_transparency_container ", style)}>
                    <div key="ToolBar Second Layer" className="square_contianer s_contianer_align">
                        <label className="label_range_container">
                            <span>{this.props.current_lang === 'en' ? "Transparency" : '透明度'}</span>
                            <div className="label_range_width">
                                <input className="range range_transparency" type="range" min="0" max="100" step="1"
                                       value={this.state.value} onChange={e => {
                                    this.handleChange(e)
                                }}/>
                            </div>
                            <div className="text_value">{parseFloat(this.state.value).toFixed(0)}</div>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let groupElement = resolveGroupElement(state.activeElements);
    return {
        targetElement: groupElement ? groupElement : state.activeElements[0],
        current_lang: state.current_lang
    }
};

export default connect(
    mapStateToProps
)(Transparency);
