import React, { Suspense, lazy } from 'react';
import './element.scss';
//import $ from 'jquery';
import classNames from "classnames";
import {connect} from 'react-redux';
//css and images
//views
//import TextElement from './TextElement';
import ImageElement from './ImageElement';

import {
    applyDragImagePreview,
    createVirtualGroup,
    setActiveElement,
    setActiveElementIdx,
    setCropMode,
    setElementProperty,
    setProxyEvent,
    setTransformMode,
    ungroupVirtualGroup
} from '../../actions';

import {adaptImageData} from "../../utilities/canvasData";

//const ImageElement = lazy(() => import('./ImageElement'));
const TextElement = lazy(() => import('./TextElement'));

class Element extends React.Component {

    isActiveElement() {
        let elementHash = this.props.data.hash;
        let isActiveElement = false;
        this.props.activeElements.forEach(item => {
            if (item && item.hash === elementHash) {
                isActiveElement = true;
            }
        });
        return isActiveElement;
    }

    handleTouchOrMouseDown(pageX, pageY, button, shiftKey, isTouch) {
        if (this.props.permission === "read") {
            return false;
        }
        if (this.props.locked) {
            return false;
        }
        if (this.props.previewOnly) {
            return false;
        }
        if (button == 0) {
            if (this.props.data.type !== 'background') {
                /* dirty hack */
                if (!isTouch) {
                    let proxyEvent = {};
                    proxyEvent.button = button;
                    proxyEvent.preventDefault = () => {
                    };
                    proxyEvent.stopPropagation = () => {
                    };
                    proxyEvent.pageX = pageX;
                    proxyEvent.pageY = pageY;
                    this.props.setProxyEvent(proxyEvent);
                }
                /* dirty hack */

                this.props.setTransformMode();
                this.props.ungroupVirtualGroup();
                if (shiftKey) {
                    let elementHashs = [];
                    this.props.ungroupVirtualGroup();
                    if (this.props.activeElements) {
                        elementHashs = this.props.activeElements.map(element => element.hash);
                    }
                    elementHashs.push(this.props.data.hash);
                    this.props.setActiveElement(elementHashs);
                    if (elementHashs.length > 1) {
                        this.props.createVirtualGroup(elementHashs);
                    }
                } else {
                    this.props.setActiveElement([this.props.data.hash]);
                    this.props.setActiveElementIdx();
                }
            } else {
                // for background
                this.props.setActiveElement([this.props.data.hash]);
                this.props.setActiveElementIdx();
                this.props.ungroupVirtualGroup();
            }
            // event.preventDefault();
            // event.stopPropagation();
        }
    }

    onTouchStart(event) {
        if (event.touches.length === 1) {
            return this.handleTouchOrMouseDown(event.touches[0].pageX, event.touches[0].pageY, 0, false, true);
        }
    }

    onMouseDown(event) {
        return this.handleTouchOrMouseDown(event.pageX, event.pageY, event.button, event.shiftKey);
    }

    onDoubleClick(event) {
        if (this.props.permission === "read") {
            return false;
        }
        if (this.props.locked) {
            return false;
        }
        if (this.props.previewOnly) {
            return false;
        }
        if (this.props.data.type === 'background') {
            this.props.setActiveElement([this.props.data.hash]);
            this.props.setActiveElementIdx();
            this.props.setCropMode();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    preventTouchOrMouseUp() {
        if (this.props.data.type === 'background') {
            this.props.setActiveElement([this.props.data.hash]);
            this.props.setActiveElementIdx();
            this.props.ungroupVirtualGroup();
        }
    }

    preventTouchEnd(event) {
        if (event.touches.length === 1) {
            this.preventTouchOrMouseUp();
        }
        event.preventDefault();
        event.stopPropagation();
    }

    preventMouseUp(event) {
        if (event.button == 0) {
            this.preventTouchOrMouseUp();
        }
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event) {
        if (this.props.permission === "read") {
            return false;
        }
        if (this.props.locked) {
            return false;
        }
        if (this.props.previewOnly) {
            return false;
        }
        // var pos = {centerX:x_pos / this.props.zoomScale, centerY:y_pos / this.props.zoomScale};
        // var page = this.props.idx;
        // this.props.addElementByData(data, pos, page);
        if ((this.props.data.type === 'photo' || this.props.data.type === 'graphic') && this.props.dragImageData && !(this.props.dragImageData.type && this.props.dragImageData.type === 'background')) {

            // console.log("onDrop", this.props.data, Math.abs(this.props.data.imageWidth / this.props.data.imageHeight - this.props.dragImageData.imageWidth / this.props.dragImageData.imageHeight) < 0.01);

            //if (Math.abs(this.props.data.imageWidth / this.props.data.imageHeight - this.props.dragImageData.imageWidth / this.props.dragImageData.imageHeight) < 0.01) {
                let newImageData = adaptImageData(this.props.data, this.props.dragImageData);
                this.props.setElementProperty(this.props.data.hash, {
                    photoUrl: newImageData.photoUrl,
                    imageWidth: newImageData.imageWidth,
                    imageHeight: newImageData.imageHeight,
                    imageOriginLeft: newImageData.imageOriginLeft,
                    imageOriginTop: newImageData.imageOriginTop
                })
            //}
            this.props.applyDragImagePreview(null, null);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onDragOver(event) {
        if (this.dragImageDataCache == this.props.dragImageData) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.dragImageDataCache = null;
        if ((this.props.data.type === 'photo' || this.props.data.type === 'graphic') && this.props.dragImageData && !(this.props.dragImageData.type && this.props.dragImageData.type === 'background')) {
            if (!this.props.data.preview || this.props.data.preview.photoUrl !== this.props.dragImageData.photoUrl) {
                console.log("onDragOver", this.props.dragImageData, Math.abs(this.props.data.imageWidth / this.props.data.imageHeight - this.props.dragImageData.imageWidth / this.props.dragImageData.imageHeight) < 0.01);
                //if (Math.abs(this.props.data.imageWidth / this.props.data.imageHeight - this.props.dragImageData.imageWidth / this.props.dragImageData.imageHeight) < 0.01) {
                    this.dragImageDataCache = this.props.dragImageData;
                    this.props.applyDragImagePreview(this.props.data.hash, this.props.dragImageData);
                    event.preventDefault();
                    event.stopPropagation();
                //}
            }
        }
    }

    onDragLeave() {
        this.dragImageDataCache = null;
        this.props.applyDragImagePreview(this.props.data.hash, null);
    }

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in this.props) {
            if (key === 'activeElements') {
                if (JSON.stringify(this.props[key]) != JSON.stringify(nextProps[key])) {
                    return true;
                }
            } else {
                if (this.props[key] != nextProps[key]) {
                    return true;
                }
            }

        }
        for (let key in this.state) {
            if (this.state[key] != nextState[key]) {
                return true;
            }
        }
        return false;
    }

    render() {
        let contentElement = null;
        if (this.props.data.type === 'text' && this.props.mode === 'overlay') {
            return '';
        } else if (this.props.data.type === 'photo' && this.props.mode === 'overlay') {
            return (
                <div className={classNames(
                    'element', 'instant-upload',
                    {is_active: this.isActiveElement() || (!this.props.previewOnly && this.props.proxyHoverElementHash && this.props.proxyHoverElementHash === this.props.data.hash)},
                    {hide: (this.props.editMode === 'crop' || this.props.editMode === 'transform') && !this.props.previewOnly && this.isActiveElement() && this.props.data.type !== 'background'}
                )} style={{
                    width: this.props.data.width * this.props.zoomScale,
                    height: this.props.data.height * this.props.zoomScale,
                    top: (this.props.data.centerY - (this.props.data.textFontSize && this.props.data.singleLine ? this.props.data.textFontSize : this.props.data.height) / 2 - (this.props.print_only ? 0 : 0.5)) * this.props.zoomScale,
                    left: (this.props.data.centerX - this.props.data.width / 2 - (this.props.print_only ? 0 : 0.5)) * this.props.zoomScale,
                    transform: `rotate(${this.props.data.rotation}deg)`,
                }}
                     onClick={(this.props.data.type === 'photo'/* || this.props.data.type == 'graphic' || this.props.data.type == 'background'*/) && !!this.props.instantUpload ? (() => this.props.instantUpload(this.props.data.hash, this.props.data.photoUrl, this.props.data.height, this.props.data.width, this.props.data.filterName)) : null}>

                </div>);
        }
        if (this.props.data.type === 'text') {
            contentElement = (
                <Suspense fallback={<span></span>}><TextElement
                    recalculateFont={this.props.recalculateFont}
                    key={`${this.props.id}_${this.props.data.hash}`}
                    data={this.props.data}
                    previewOnly={this.props.previewOnly}
                    zoomScale={this.props.zoomScale}
                /></Suspense>);
        } else if (this.props.data.type === 'photo' || this.props.data.type === 'graphic') {
            contentElement = (
                <ImageElement
                    instantUpload={this.props.instantUpload}
                    key={`${this.props.id}_${this.props.data.hash}`}
                    data={this.props.data}
                    previewOnly={this.props.previewOnly}
                    zoomScale={this.props.zoomScale}
                />);
        } else if (this.props.data.type === 'background') {
            contentElement = (
                <ImageElement
                    instantUpload={this.props.instantUpload}
                    key={`${this.props.id}_${this.props.data.hash}`}
                    data={this.props.data}
                    previewOnly={this.props.previewOnly}
                    zoomScale={this.props.zoomScale}
                />);
        }

        return (
            <div className={classNames(
                'element',
                {is_active: this.isActiveElement() || (!this.props.previewOnly && this.props.proxyHoverElementHash && this.props.proxyHoverElementHash === this.props.data.hash)},
                {pin_text_edit_btn: (this.isActiveElement() && this.props.activeElements.length === 1)},
                {'print_only': this.props.print_only},
                {preview_only: this.props.previewOnly},
                {hide: (this.props.editMode === 'crop' || this.props.editMode === 'transform') && !this.props.previewOnly && this.isActiveElement() && this.props.data.type !== 'background'}
            )} style={{
                width: this.props.data.width * this.props.zoomScale,
                height: this.props.data.height * this.props.zoomScale,
                top: (this.props.data.centerY - (this.props.data.textFontSize && this.props.data.singleLine ? this.props.data.textFontSize : this.props.data.height) / 2 - (this.props.print_only ? 0 : 0.5)) * this.props.zoomScale,
                left: (this.props.data.centerX - this.props.data.width / 2 - (this.props.print_only ? 0 : 0.5)) * this.props.zoomScale,
                transform: `rotate(${this.props.data.rotation}deg)`,
            }}>
                <div className={classNames("transform_tool_wrapper")}>
                    <div
                        className={classNames("content_element", {
                            "background_element": this.props.data.type === 'background',
                            "clip": this.props.data.type !== 'text',
                            "overflow": this.props.data.type === 'text'
                        })}
                        style={{
                            width: this.props.data.width * this.props.zoomScale,
                            height: this.props.data.height * this.props.zoomScale,
                        }}
                    >
                        {contentElement}
                    </div>
                    <div
                        className={classNames("preview_container", {'hide': !(this.props.data.preview && this.props.dragImageData)})}/>
                    <div className="overlay" onTouchStart={this.onTouchStart.bind(this)}
                         onMouseDown={this.onMouseDown.bind(this)} onTouchEnd={this.preventTouchEnd.bind(this)}
                         onMouseUp={this.preventMouseUp.bind(this)} onDoubleClick={this.onDoubleClick.bind(this)}
                         onDrop={this.onDrop.bind(this)} onDragOver={this.onDragOver.bind(this)}
                         onDragLeave={this.onDragLeave.bind(this)}>
                    </div>
                </div>
            </div>
        )
    }


}

const mapStateToProps = state => ({
    activeElements: state.activeElements,
    // zoomScale: state.zoomScale,
    editMode: state.editMode,
    dragImageData: state.dragImageData,
    permission: state.permission,
    locked: !!state.activeElements.find(activeElement => activeElement && !!activeElement.locked),
    proxyHoverElementHash: state.proxyHoverElementHash,
});

const mapDispatchToProps = dispatch => ({
    setActiveElement: (elementHash) => dispatch(setActiveElement(elementHash)),
    setElementProperty: (elementHash, changes) => dispatch(setElementProperty(elementHash, changes)),
    setCropMode: () => dispatch(setCropMode()),
    setTransformMode: () => dispatch(setTransformMode()),
    setActiveElementIdx: () => dispatch(setActiveElementIdx()),
    ungroupVirtualGroup: () => dispatch(ungroupVirtualGroup()),
    createVirtualGroup: (elements) => dispatch(createVirtualGroup(elements)),
    applyDragImagePreview: (hash, imageData) => dispatch(applyDragImagePreview(hash, imageData)),
    setProxyEvent: (proxyEvent) => dispatch(setProxyEvent(proxyEvent)),
});

Element.defaultProps = {
    recalculateFont: true,
    mode: 'visual'
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Element);
